import {Injectable} from '@angular/core';

@Injectable()
export class DateUtils {

  constructor() {
  }

  /**
   * 日期对象转为日期字符串
   * @param date 需要格式化的日期对象
   * @param sFormat 输出格式,默认为yyyy-MM-dd                         年：y，月：M，日：d，时：h，分：m，秒：s
   * @example  dateFormat(new Date())                                "2017-02-28"
   * @example  dateFormat(new Date(),'yyyy-MM-dd')                   "2017-02-28"
   * @example  dateFormat(new Date(),'yyyy-MM-dd hh:mm:ss')         "2017-02-28 09:24:00"
   * @example  dateFormat(new Date(),'hh:mm')                       "09:24"
   * @example  dateFormat(new Date(),'yyyy-MM-ddThh:mm:ss+08:00')   "2017-02-28T09:24:00+08:00"
   * @returns {string}
   */
 static dateFormat(date: Date, sFormat:string = 'yyyy-MM-dd'): string {
    let time = {
      Year: 0,
      TYear: '0',
      Month: 0,
      TMonth: '0',
      Day: 0,
      TDay: '0',
      Hour: 0,
      THour: '0',
      hour: 0,
      Thour: '0',
      Minute: 0,
      TMinute: '0',
      Second: 0,
      TSecond: '0',
      Millisecond: 0
    };
    time.Year = date.getFullYear();
    time.TYear = String(time.Year).substr(2);
    time.Month = date.getMonth() + 1;
    time.TMonth = time.Month < 10 ? "0" + time.Month : String(time.Month);
    time.Day = date.getDate();
    time.TDay = time.Day < 10 ? "0" + time.Day : String(time.Day);
    time.Hour = date.getHours();
    time.THour = time.Hour < 10 ? "0" + time.Hour : String(time.Hour);
    time.hour = time.Hour < 13 ? time.Hour : time.Hour - 12;
    time.Thour = time.hour < 10 ? "0" + time.hour : String(time.hour);
    time.Minute = date.getMinutes();
    time.TMinute = time.Minute < 10 ? "0" + time.Minute : String(time.Minute);
    time.Second = date.getSeconds();
    time.TSecond = time.Second < 10 ? "0" + time.Second : String(time.Second);
    time.Millisecond = date.getMilliseconds();

    return sFormat.replace(/yyyy/ig, String(time.Year))
      .replace(/yyy/ig, String(time.Year))
      .replace(/yy/ig, time.TYear)
      .replace(/y/ig, time.TYear)
      .replace(/MM/g, time.TMonth)
      .replace(/M/g, String(time.Month))
      .replace(/dd/ig, time.TDay)
      .replace(/d/ig, String(time.Day))
      .replace(/HH/g, time.THour)
      .replace(/H/g, String(time.Hour))
      .replace(/hh/g, time.Thour)
      .replace(/h/g, String(time.hour))
      .replace(/mm/g, time.TMinute)
      .replace(/m/g, String(time.Minute))
      .replace(/ss/ig, time.TSecond)
      .replace(/s/ig, String(time.Second))
      .replace(/fff/ig, String(time.Millisecond))
  }

  /**
   * 计算增加后的日期
   * @param interval 按什么（年，月，日，小时）计算
   * @param number 计算值
   * @param date 当前日期
   */
  static dateAdd(interval, number, date) {
    switch (interval) {
      //年
      case "y": {
          date.setFullYear(date.getFullYear() + number);
          return date;
      }
      //季度
      case "q": {
          date.setMonth(date.getMonth() + number * 3);
          return date;
      }
      case "m": {
          date.setMonth(date.getMonth() + number);
          return date;
      }
      case "w": {
          date.setDate(date.getDate() + number * 7);
          return date;
      }
      case "d": {
          date.setDate(date.getDate() + number);
          return date;
      }
      case "h": {
          date.setHours(date.getHours() + number);
          return date;
      }
      case "m": {
          date.setMinutes(date.getMinutes() + number);
          return date;
      }
      case "s": {
          date.setSeconds(date.getSeconds() + number);
          return date;
      }
      default: {
          date.setDate(date.getDate() + number);
          return date;
      }
    }
  }

  /**
   * 计算减去后的日期
   * @param interval 按什么（年，月，日，小时）计算
   * @param number 计算值
   * @param date 当前日期
   */
  static dateSub(interval, number, date) {
    switch (interval) {
      //年
      case "y": {
          date.setFullYear(date.getFullYear() - number);
          return date;
      }
      //季度
      case "q": {
          date.setMonth(date.getMonth() - number * 3);
          return date;
      }
      case "m": {
          date.setMonth(date.getMonth() - number);
          return date;
      }
      case "w": {
          date.setDate(date.getDate() - number * 7);
          return date;
      }
      case "d": {
          date.setDate(date.getDate() - number);
          return date;
      }
      case "h": {
          date.setHours(date.getHours() - number);
          return date;
      }
      case "m": {
          date.setMinutes(date.getMinutes() - number);
          return date;
      }
      case "s": {
          date.setSeconds(date.getSeconds() - number);
          return date;
      }
      default: {
          date.setDate(date.getDate() - number);
          return date;
      }
    }
  }

  // 日期时间段处理   startTime 开始时间    endTime 结束时间
  static check(startTime,endTime) {
    // if (startTime.length > 0 && endTime.length > 0) {
    //   var startTmp = startTime.split("-");
    //   var endTmp = endTime.split("-");
    //   var sd = new Date(startTmp[0], startTmp[1], startTmp[2]);
    //   var ed = new Date(endTmp[0], endTmp[1], endTmp[2]);
    //   if (sd.getTime() > ed.getTime()) {
    //     return false;
    //   }
    // }
    // return true;

    var start = new  Date(startTime);
    var end  = new Date(endTime);
    if(start > end ) {
    return false;
    } else {
    return true;
    }
  }

}
