import { EncryptUtils } from '../utils/EncryptUtils';
import { DateUtils } from '../utils/DateUtils';
import { Constants } from '../Constants';
import { Config } from "../Config";
import { Injectable } from '@angular/core';
import {
  Http, Response, Headers, RequestOptions, URLSearchParams, RequestOptionsArgs, RequestMethod
} from '@angular/http';

import { Observable  } from "rxjs";
import { map } from 'rxjs/operators';
import {StorgeUtils} from "../utils/StorageUtils";
import * as addSeconds from "date-fns/add_seconds";
import {Router} from "@angular/router";


@Injectable()
export class HttpService {

    private config: Config;
    headers : Headers = new Headers();
    httpTimeOut = 8000;//http timeout设置
    isRefreshing = true;
    subscribers = [];


    constructor(public http: Http,
                private encrypt : EncryptUtils,
                public local: StorgeUtils,
                private router: Router
                ) {

      this.config = Config.getInstance();//实例化config

    }

    public setHeaders(_header : Headers){
        this.headers = _header;
    }

    public getHeaders() : Headers{
        return this.headers;
    }

    private request(api_url: string, is_agent : boolean = false, options: RequestOptionsArgs): Observable<Response> {
        let url = this.replaceUrl(api_url,is_agent);
        if(options.headers == undefined){
            options.headers = new Headers();
        }

        if(is_agent){
            options.headers.append(Constants.Header.ClientId,this.encrypt.encrypt(Constants.ClientId));
        }else{
            options.headers.append(Constants.Header.ClientId,Constants.ClientId);
            //options.headers.append(Constants.Header.ClientSecret,Constants.ClientSecret);
            if(this.local.get(Constants.LocalStorage.AccessToken)){
              options.headers.append(Constants.Header.AccessToken,this.local.get(Constants.LocalStorage.AccessToken));
            }
            //debugger;
            //console.log('当前account_id',this.config.account_id);
            if(this.config.account_id){
              options.headers.append(Constants.Header.AccountId, this.config.account_id);
            }
            // if(this.config.current_system) {
            //   options.headers.append(Constants.Header.currentSystem, this.config.current_system);
            // }
        }

        var observable: Observable<Response > = Observable.create((observer) => {
            // console.log('%c 请求前 %c', 'color:blue', '', 'url=>', url, 'options=>', options);
            this.http.request(url, options)
                .subscribe((res: any) => {

                  if(res.url.indexOf("/resume/talent/"+ this.config.resume_id +"/download") > -1){
                    // 扫码登录获取结果集特殊处理
                    res._body  = {data : res._body };
                  }
                    observer.next(res);
                    observer.complete();
                }, err => {
                    // console.log('%c 请求失败 %c', 'color:red', '', 'url=>', url, 'options=>', options, 'err=>', err);
                    //observer.error(err);

                    if(0 === err.status){
                        //this.uiUtils.showToast("无法连接网络,请检查网络设置","bottom");
                        /*this.uiUtils.confirmDialog('网络错误','连接服务器失败！请稍后重试','退出','重试').then(()=>{
                            this.platform.exitApp();
                        },()=>{
                            //this.navCtrl.setRoot(this.navCtrl.getActive().component);
                        });

                        this.uiUtils.confirmDialogOneBtn('网络错误','连接服务器失败！请稍后重试').then(()=>{
                            this.platform.exitApp();
                        });*/
                        observer.error(err);
                    }else{
                      let errorMsg = this.requestFailed(url, options, err);//处理请求失败
                      if(errorMsg.code  == '500' || errorMsg.code  == '503') {
                        let err_msg = {
                          'code': errorMsg.code,
                          'err_text': '',
                        };
                        errorMsg.error && errorMsg.error._body ? err_msg.err_text = JSON.parse(errorMsg.error._body).Errmsg : '';
                        this.config.show_Request_Error_Popup = err_msg;
                      }
                      observer.error(errorMsg);
                      // let errCode = JSON.parse(err._body);
                      // if(errCode.Errcode == '1021') {
                      //   this.redirectLogin();
                      // } else {
                      //
                      // }


                      // let errCode = JSON.parse(err._body);
                      // if (errCode.Errcode == '1006' || errCode.Errcode == '1016') {
                      //   // 刷新token的函数,这需要添加一个开关，防止重复请求
                      //   if (this.isRefreshing) {
                      //     this.refresh_token();
                      //   }
                      //   this.isRefreshing = false;
                      //   // 这个Promise函数很关键
                      //   const retryOriginalRequest = new Promise((resolve) => {
                      //     this.addSubscriber(() => {
                      //       resolve(this.request(api_url, is_agent, options))
                      //     })
                      //   });
                      //
                      //   retryOriginalRequest.then((res)=>{
                      //     observer.next(res,'res');
                      //     observer.complete();
                      //   },(reject )=>{
                      //     console.log('reject ',reject );
                      //   });
                      // } else {
                      //   observer.error(errorMsg)
                      // }

                    }
            })
        });

        observable = observable.pipe(
                        map(res => {
                          try {
                                return res.json();
                            } catch(e) {
                                return "{}";
                            }
                        })
                    );
                    /*.retryWhen(e => e.scan<number>((errorCount, err) => {
                        console.log(err);
                        if (errorCount >= 2) {
                            throw err;
                        }
                        return errorCount + 1;
                    }, 0).delay(1000 * 3));
                    .retryWhen(attempt => {
                        return attempt.flatMap(res => {
                            console.log("retryWhen res");
                            console.log(res);
                          // if it's the first round and an authentication error try to refresh token
                            if (500 === res.status || 0 === res.status) {
                                return this.uiUtils.confirmDialogOneBtn('错误','服务器错误！请稍后重试').then(()=>{
                                            this.platform.exitApp();
                                        });
                            }else if(403 === res.status || 401 === res.status){
                               this.refreshToken();
                            }else{
                                return Observable.throw(res);
                            }

                        })
                    }).delay(1000 * 3);*/

        return observable;
    }



    /**
     *  var params = new URLSearchParams();
     *  params.set(‘search‘, term); // the user‘s search value
     *  params.set(‘action‘, ‘opensearch‘);
     *  params.set(‘format‘, ‘json‘);
     *
     */
    public get(url: string, paramMap: any = undefined, is_agent: boolean = false): Observable<Response> {

      return this.request(url, is_agent, new RequestOptions({
        method: RequestMethod.Get,
        search: HttpService.buildURLSearchParams(paramMap)
      }));

    }



     /* 文件下载 */
  public getFile(url: string, paramMap: any = undefined, is_agent: boolean = false): Observable<Response> {
    return this.request(url, is_agent, new RequestOptions({
      method: RequestMethod.Get,
      search: HttpService.buildURLSearchParams(paramMap),
      responseType:3,
    }));
  }

    // 默认Content-Type为application/json;
    //let body = JSON.stringify({product});
    public post(url: string, body: any = undefined, is_agent: boolean = false): Observable<Response> {
        return this.request(url, is_agent, new RequestOptions({
            method: RequestMethod.Post,
            body: body,
            headers: new Headers({
                'Content-Type': 'application/json; charset=UTF-8'
            })
        }));
    }

    public postFormData(url: string, paramMap: any = null, is_agent: boolean = false): Observable<Response> {
        return this.request(url, is_agent, new RequestOptions({
            method: RequestMethod.Post,
            //search: HttpService.buildURLSearchParams(paramMap).toString(),
            body: this.buildPostParams(paramMap),
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            })
        }));
    }

    public put(url: string, body: any = null, is_agent: boolean = false): Observable<Response> {
        return this.request(url,is_agent, new RequestOptions({
            method: RequestMethod.Put,
            body: body
        }));
    }

    public delete(url: string, paramMap: any = null, is_agent: boolean = false): Observable<Response> {
        return this.request(url,is_agent, new RequestOptions({
            method: RequestMethod.Delete,
            search: HttpService.buildURLSearchParams(paramMap).toString()
        }));
    }

    public patch(url: string, body: any = null, is_agent: boolean = false): Observable<Response> {
        return this.request(url,is_agent, new RequestOptions({
            method: RequestMethod.Patch,
            body: body
        }));
    }

    public head(url: string, paramMap: any = null, is_agent: boolean = false): Observable<Response> {
        return this.request(url, is_agent,new RequestOptions({
            method: RequestMethod.Head,
            search: HttpService.buildURLSearchParams(paramMap).toString()
        }));
    }

    public options(url: string, paramMap: any = null, is_agent: boolean = false): Observable<Response> {
        return this.request(url, is_agent,new RequestOptions({
            method: RequestMethod.Options,
            search: HttpService.buildURLSearchParams(paramMap).toString()
        }));
    }

    private buildPostParams(params : any){
        let paramsArray = [];
        Object.keys(params).forEach((key) => {
          //console.log(params[key] instanceof Object);
          if(params[key] instanceof Array){
            params[key].forEach((val)=>{
              paramsArray.push(key+'['+params[key].indexOf(val)+']'+'=' + encodeURIComponent(val));
            });
          }else if(params[key] instanceof Object){
            Object.keys(params[key]).forEach((keys) => {
              paramsArray.push(key+'['+keys+']'+'=' + encodeURIComponent(params[key][keys]));
            })
          }else{
            paramsArray.push(key + '=' + encodeURIComponent(params[key]));
          }

        })
        params = paramsArray.join("&");

        return params;
    }

     /**
     * 将对象转为查询参数
     * @param paramMap
     * @returns {URLSearchParams}
     */
    private static buildURLSearchParams(paramMap): URLSearchParams {
        let params = new URLSearchParams();
        if (!paramMap) {
            return params;
        }
        for (let key in paramMap) {
            let val = paramMap[key];
            if (val instanceof Date) {
                val = DateUtils.dateFormat(val, 'yyyy-MM-dd hh:mm:ss')
            }
            params.set(key, val);
        }
        return params;
    }

    /**
     * 处理请求失败事件
     * @param url
     * @param options
     * @param err
     */
    private requestFailed(url: string, options: RequestOptionsArgs, err) : any {
        //console.log('%c 请求失败 %c', 'color:red', '', 'url', url, 'options', options, 'err', err);
        let errorJson = {
            "code" : 0,
            "msg" : '',
            "error" : err
        };
        let status = err.status;

        errorJson.code = status;
        if (status === 0) {
            errorJson.msg = '请求失败，请求响应出错，请稍后再试';
        } else if (status === 404) {
            errorJson.msg = '请求失败，未找到请求地址，请稍后再试';
        } else if (status === 500) {
            errorJson.msg = '请求失败，服务器出错，请稍后再试';
        }else{
            errorJson.code = err.Errcode;
            errorJson.msg = err.Errmsg;
        }

        return errorJson;
    }

    /**
     * url中如果有双斜杠替换为单斜杠
     * 如:http://88.128.18.144:8080//api//demo.替换后http://88.128.18.144:8080/api/demo
     * @param url
     * @returns {string}
     */
    private replaceUrl(url,is_agent : boolean = false) {
        //if (url.indexOf('http://') == -1) {
            if(is_agent){
                url = Constants.AgentApiUrl + url;
            } else if(url.indexOf('questionnaire')> -1) {
                url = Constants.dataApi + url;
              }else if(url.indexOf('CdPublication')> -1) {
                url = Constants.cdApi + url;
              }else if(url.indexOf('v2')> -1) {
                url = Constants.recommendApi + url;
              }   else {
                url = Constants.ApiUrl + url;
              }

        return url;
    }

   // 刷新token
  private refresh_token() {
    let refresh_params = {
      "grant_type": "refresh_token",
      "code": '',
      "refresh_token":this.local.get('refreshtoken'),
      "loginname": '',
      "password": '',
      "login_source": '',
    };
    return this.postFormData("/api/oauth2/access_token/", refresh_params,true).subscribe(async (res: any) => {
      console.log("refresh_token =>" + res.access_token);
      this.config.token = res.access_token;
      let expiresDate = addSeconds(new Date(), res.expires_in);
      this.local.set(Constants.LocalStorage.AccessToken, res.access_token);
      this.local.set(Constants.LocalStorage.RefreshToken, res.refresh_token);
      this.local.set(Constants.LocalStorage.TokenExpiresDate, expiresDate);
      this.onAccessTokenFetched();
      this.isRefreshing = true;
    }, err => {
      console.log("err",err);
      let errCode = JSON.parse(err.code);
      if(errCode == '1017') {
        console.log('appear bug code=>>>',errCode);
      }
      this.redirectLogin();
    })
  }


  public onAccessTokenFetched() {
    this.subscribers.forEach((callback) => {
      callback();
    });
    this.subscribers = [];
  }

  public addSubscriber(callback) {
    this.subscribers.push(callback)
  }






    /*  重新登陆 */
  public redirectLogin() {
    this.config.token = '';
    this.local.clear();
    this.router.navigateByUrl('login');
  }

}
