import {Injectable} from "@angular/core";
import {Validators as angularValidators, AbstractControl} from '@angular/forms';

@Injectable()
export class Validators extends angularValidators {

  static isEmpty(value): boolean {
    return value == null || typeof value === 'string' && value.length === 0;
  }

  static isNotEmpty(value): boolean {
    return !Validators.isEmpty(value);
  }

  /*E-mail*/
  static isEmail(vaule: string): boolean {
    //校验规则来源 https://www.emailregex.com/
    let Expression = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    //let Expression = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,4}){1,2})$/;
    //var Expression = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9_-]+((\.|-)[A-Za-z0-9_-]+)*\.(?:asia|com|cn|org|net|gov|mil|biz|info|mobi|tech|name|aero|jobs|museum|travel|com\.cn|top|[a-z]{2,6})+$/;

    let objExp = new RegExp(Expression);
    if (objExp.test(vaule.toLocaleLowerCase()) == true) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 判断URL地址
   * @param url
   */
  static checkURL(url: string) {
    //let Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
    let Expression = "^((https|http|ftp|rtsp|mms)?://)"
      + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
      + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
      + "|" // 允许IP和DOMAIN（域名）
      + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
      + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
      + "[a-z]{2,6})" // first level domain- .com or .museum
      + "(:[0-9]{1,4})?" // 端口- :80
      + "((/?)|" // a slash isn't required if there is no file name
      + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";

    let objExp = new RegExp(Expression);
    if (objExp.test(url) == true) {
      return true;
    } else {
      return false;
    }
  }

  // 手机号
  static isMobilePhone(phone: string) {
    let Expression = /^1[2|3|4|5|7|6|8|9][0-9]{9}$/;
    let objExp = new RegExp(Expression);
    if (objExp.test(phone) == true) {
      return true;
    } else {
      return false;
    }
  }

  static isPassword(pwd: string) {
    let Expression = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/;//不为纯数字或字母
    let objExp = new RegExp(Expression);
    if (objExp.test(pwd) == true) {
      return true;
    } else {
      return false;
    }
  }


  /* 身份证 */
  // static isIdentity(identity) {
  //   var reg = /^\d{15}(\d{2}[A-Za-z0-9])?$/;
  //   let objExp = new RegExp(reg);
  //   if (objExp.test(identity) == true) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  static isIdentity(identity){
    if(identity.length == 18){
      identity = identity.split('')
      let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
      let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4 ,3, 2];
      let sum = 0;
      let ai = 0;
      let wi = 0;
      for(let i = 0; i < 17; i++){
        ai = identity[i];
        wi = factor[i];
        sum += ai * wi;
      }
      if(parity[sum%11] != identity[17].toUpperCase()){
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }


  /* 固定电话 */
  static isValidPhone(number) {
    let phone = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
    let objExp = new RegExp(phone);
    if (objExp.test(number) == true) {
      return true;
    } else {
      return false;
    }
  }

  /* 固定电话跟手机号判断 */
  static isValidSpecialPlane(number) {
    //let phone = /^(((0\d{2,3})-)|(0\d{2,3}))(\d{7,8})(-(\d{1,4}))?$/;
    let phone = /^((\d{7,8})|(0\d{2,3})(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{5}|\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{5}\d{4}|\d{3}|\d{2}|\d{1}))$/;
    let Expression = /^1[3|4|5|7|6|8|9][0-9]{9}$/;
    if(phone.test(number)||Expression.test(number)) {
      return true;
    }else {
      return false;
    }
  }


  static  isVerifyNumber(number) {
    let num = /^[0-9]*$/;
    let objExp = new RegExp(num);
    if (objExp.test(number) == true) {
      return true;
    }
  }
  static isChinese(keywordscn){
    let reg = /^[\u4E00-\u9FA5]+$/;
    if(!reg.test(keywordscn)){
      return false;
    }
    return true;
  }


  static isSpecialPlane(phone) {
    const isPhone = /^(0[0-9]{2,3})?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$|(^1[3|4|5|7|8|6|9][0-9]\d{8}$)/
    ; // 0571-86295197
    // const isPhone02 = /^\d{3,4}-\d{3,4}-\d{3,4}$/; // 4001-550-520
    // const isMob = /^1[0-9]{10}$/;
    const valuePhone = phone.trim();

    //避免 180-3913-8022 这样的错误格式
    // if(isPhone02.test(valuePhone))
    // {
    //   let index = valuePhone.substring(0, 1);
    //   if(index=='1')
    //   {
    //     return false;
    //   }
    // }
    if (isPhone.test(valuePhone)) { // 正则验证
      return true;
    }
    return false;
  }


  /*手机号码*/
  static phone = function (control: AbstractControl) {
    return Validators.validatorsByPattern('phone', control, '1[0-9]{10,10}');
  };

  /*中文*/
  static chinese = function (control: AbstractControl) {
    return Validators.validatorsByPattern('chinese', control, '[(\u4e00-\u9fa5)]+');
  };

  /*英文、数字包括下划线*/
  static legallyNamed = function (control: AbstractControl) {
    return Validators.validatorsByPattern('legallyNamed', control, '[A-Za-z0-9_]+');
  };

  private static validatorsByPattern = function (name: string, control: AbstractControl, pattern: string) {
    let validatorFn = Validators.pattern(pattern)(control);
    if (validatorFn != null) {
      validatorFn[name] = validatorFn['pattern'];
    }
    return validatorFn;
  };
}
