import { Events } from 'ionic-angular';
import { EventService } from './../services/EventService';
import { LoginCollegeService } from './login-college/login-college.service';
import { LoginUserProfileService } from './login-user-profile/login-user-profile.service';
import { LoginPositionSkillService } from './login-position-skill/login-position-skill.service';
import { LoginUserSkillService } from './login-user-skill/login-user-skill.service';
import { bindingMobileService } from './binding-mobile/binding-mobile.service';
import { MessageService } from 'primeng/api';
import { loginService } from './login/login.service';
import { LoginPositionService } from './login-position/login-position.service';
import { WorkspaceService } from './workspace/workspace.service';
import {ConfirmationService} from 'primeng/api';
import { StorgeUtils } from './../provider/utils/StorageUtils';
import { EncryptUtils } from './../provider/utils/EncryptUtils';
import { Api } from './../provider/Api';
import { HttpService } from './../provider/http/HttpService';
import { appRoutes } from './app.router';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from "@angular/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {FileUploadModule} from 'primeng/fileupload';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BindingMobileComponent } from './binding-mobile/binding-mobile.component';
import { LoginUserProfileComponent } from './login-user-profile/login-user-profile.component';
import { LoginCollegeComponent } from './login-college/login-college.component';
import { LoginUserSkillComponent } from './login-user-skill/login-user-skill.component';
import { LoginPositionComponent } from './login-position/login-position.component';
import { LoginPositionSkillComponent } from './login-position-skill/login-position-skill.component';
import { APP_BASE_HREF, LocationStrategy, HashLocationStrategy } from '@angular/common';
import {XiaoMiMessage} from "../provider/utils/XiaoMiMessage";
import {ChatUtils} from "../provider/utils/ChatUtils";
import { RouterTransferPageComponent } from './router-transfer-page/router-transfer-page.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';






@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BindingMobileComponent,
    LoginUserProfileComponent,
    LoginCollegeComponent,
    LoginUserSkillComponent,
    LoginPositionComponent,
    LoginPositionSkillComponent,
    RouterTransferPageComponent,
    
    ],
  imports: [
    CalendarModule,
    DropdownModule,
    SelectButtonModule,
    FileUploadModule,
    ConfirmDialogModule,
    SplitButtonModule,
    BrowserModule,
    HttpModule,
    DialogModule,
    BrowserAnimationsModule,
    FormsModule,
    TableModule,
    ProgressBarModule,
    ToastModule,
    ButtonModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' },
    WorkspaceService,
    ConfirmationService,
    loginService,
    bindingMobileService,
    LoginUserSkillService,
    LoginPositionService,
    LoginPositionSkillService,
    LoginUserProfileService,
    LoginCollegeService,
    HttpService,
    MessageService,
    EncryptUtils,
    StorgeUtils,
    Api,
    EventService,
    Events,
    XiaoMiMessage,
    ChatUtils

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
