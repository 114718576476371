import { Config } from '../../provider/Config';
import { MessageService } from 'primeng/api';


/**
 * 页面基类
 * 显示/隐藏加载页面
 * toast显示信息
 */
export class BasePage {

    config = Config.getInstance();
    constructor(
      public messageService: MessageService,
    ){
    }

    
    showLoading(){
        setTimeout(() => {
            this.config.showLoading = true;
        }, 200);
    }

    hideLoading(){
        setTimeout(() => {
            this.config.showLoading = false;
        }, 200);
        
    }

    showMsg(msg : string){
        this.messageService.add({severity:'info', summary : '提示', detail : msg});
    }




}
