import { LoginPositionSkillComponent } from './login-position-skill/login-position-skill.component';
import { LoginPositionComponent } from './login-position/login-position.component';
import { LoginCollegeComponent } from './login-college/login-college.component';
import { LoginUserProfileComponent } from './login-user-profile/login-user-profile.component';
import { LoginUserSkillComponent } from './login-user-skill/login-user-skill.component';
import { LoginComponent } from './login/login.component';
import { BindingMobileComponent } from './binding-mobile/binding-mobile.component';
import { Routes } from '@angular/router';
import { RouterTransferPageComponent } from './router-transfer-page/router-transfer-page.component';

export const appRoutes : Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'bindingmobile',
        component: BindingMobileComponent
    },
    {
        path: 'userprofile',
        component: LoginUserProfileComponent
    },
    {
        path: 'usercollege',
        component: LoginCollegeComponent
    },
    {
        path: 'userskill',
        component: LoginUserSkillComponent
    },
    {
        path: 'userposition',
        component: LoginPositionComponent
    },
    {
        path: 'userpositionskill',
        component: LoginPositionSkillComponent
    },
    {
        path:'routerTransfer',
        component:RouterTransferPageComponent
    },
    {
        path: 'workspace',
        loadChildren: './workspace/workspace.module#WorkspaceModule'
        /*component: WorkspaceComponent,
        children:[
            {path: 'home',component: HomeComponent},
            {path: 'resume',component: ResumeComponent},
            {path: 'resume-preview',component: ResumePreviewComponent},
            //{path: '', redirectTo: 'home', pathMatch: 'full'},
        ]*/
    },
    {
        path: '**',
        component: LoginComponent
    }
];
