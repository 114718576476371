import { Api } from './../../provider/Api';
import { BaseService } from './../../services/BaseService';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginPositionSkillService extends BaseService{

    constructor(public api : Api){
        super();
    }

    get(){
        this.page.showLoading();
        this.subscription.add(
            this.api.getCareerSkill()
                .subscribe((res: any) => {
                    console.log(res);
                    this.page.hideLoading();
                    this.page.setList(res);
                }, err => {
                    let errorMsg = JSON.parse(err.error._body);
                    this.page.showMsg(errorMsg.Errmsg);
                    //this.page.showMsg(err.msg);
                    this.page.hideLoading();
                })
        );
    }

    submit(params : any){
        this.page.showLoading();
        this.subscription.add(
            this.api.updateProSkill(params)
                .subscribe((res: any) => {
                    console.log(res);
                    this.page.hideLoading();
                    this.page.done();
                }, err => {
                    let errorMsg = JSON.parse(err.error._body);
                    this.page.showMsg(errorMsg.Errmsg);
                    //this.page.showMsg(err.msg);
                    this.page.hideLoading();
                })
        );
    }
}