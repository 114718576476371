import { DateUtils } from './../../provider/utils/DateUtils';
import { LoginCollegeService } from './login-college.service';
import { BasePage } from './../base/BasePage';
import { Component, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-college',
  templateUrl: './login-college.component.html',
  styleUrls: ['./login-college.component.css']
})
export class LoginCollegeComponent extends BasePage implements OnInit {

  cn: any;

  collegeInput = '';
  collegeResult: any;
  collegeItem: any;

  majorInput = '';
  majorResult: any;
  majorItem: any;

  educationList: SelectItem[] = new Array();
  educationItems: any;

  collegeStartdate: any;
  collegeEnddate: any;
  setTreeAllList = new Array()

  constructor(public messageService: MessageService,
    public service: LoginCollegeService,
    public router: Router,) {
    super(messageService);
  }

  ngOnInit() {

    this.service.init(this);
    this.service.getEducation();

    this.cn = {
      firstDayOfWeek: 0,
      dayNames: ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
      dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
      monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
      monthNamesShort: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      today: '今天',
      clear: '清除'
    };
  }

  setEducationData(res) {
    res.forEach((val) => {
      let data = { label: val.text, value: val.code };
      this.educationList.push(data);
    });
  }

  searchCollege(ev: any) {
    let Expression = "^[\\u4E00-\\u9FA5]{2,}$";
    let objExp = new RegExp(Expression);

    if (this.collegeInput != "") {
      if (objExp.test(this.collegeInput)) {
        let search = "school";
        let dictLevel = 1;
        this.service.collegeSearch(search, this.collegeInput, dictLevel);
      }
    } else {
      this.collegeInput = "";
      this.collegeResult = null;
    }
  }

  setCollegeResult(res) {
    this.collegeResult = res;
  }

  onCollegeSelect(item: any) {
    this.collegeInput = item.text;
    this.collegeResult = null;
    this.collegeItem = item;
  }

  // searchMajor(ev: any) {
  //   let Expression = "^[\\u4E00-\\u9FA5]{2,}$";
  //   let objExp = new RegExp(Expression);

  //   if(this.majorInput != ""){
  //     if(objExp.test(this.majorInput)){
  //       let search = "major";
  //       let dictLevel = 3;
  //       this.service.collegeSearch(search,this.majorInput,dictLevel);
  //     }
  //   }else{
  //     this.majorInput = "";
  //     this.majorResult = null;
  //   }
  // }

  searchTreeAll(tree, str) {
    let itemList = []
    tree.forEach((v, i) => {
      if (v.text.includes(str)) {
        itemList.push(v)
      }
    })
    return itemList
  }
  // 切换教育经历->学历
  educationItemsChange() {
    this.majorInput = "";
    this.majorResult = null;
    console.log(this.educationItems, '学历')
    let majorType = 'major_postgraduate_2021'//研究生
    if (this.educationItems === '01') {
      majorType = 'major_secondary_vocational_2021'//中专
    } else if (this.educationItems === '02') {
      majorType = 'major_junior_college_2021'//大专
    } else if (this.educationItems === '03' || this.educationItems === '08') {
      majorType = 'major_undergraduate_2021'//本科
    }

    this.service.getEducationList(majorType)
  }

  setTreeAll(res) {
    this.setTreeAllList = res;
    // let majorResultList = this.searchTreeAll(res, this.majorInput, ['text'], 3)
    // console.log(majorResultList,'专业列表')
  }

  // 模糊查询专业
  searchMajor() {


    let Expression = "^[\\u4E00-\\u9FA5]{2,}$";
    let objExp = new RegExp(Expression);

    if (this.majorInput != "") {
      if (objExp.test(this.majorInput)) {
        let majorResultList = this.searchTreeAll(this.setTreeAllList, this.majorInput)
        console.log(majorResultList, '专业列表')
        if (majorResultList) {
          this.majorResult = majorResultList
        }
      }
    } else {
      this.majorInput = "";
      this.majorResult = null;
    }
  }

  setMajorResult(res) {
    this.majorResult = res;
  }

  onMajorSelect(item: any) {
    this.majorInput = item.text;
    this.majorResult = null;
    this.majorItem = item;
  }

  next() {
    if (!this.collegeItem) {
      return this.showMsg("请选择学校");
    }
    if (!this.educationItems) {
      return this.showMsg("请选择学历");
    }
    if (!this.majorInput) {
      return this.showMsg("请选择专业");
    }
    if (!this.collegeStartdate) {
      return this.showMsg("请选择入学日期");
    }
    if (!this.collegeEnddate) {
      return this.showMsg("请选择毕业日期");
    }

    let education_text;
    for (let i = 0; i < this.educationList.length; i++) {
      if (this.educationItems == this.educationList[i].value) {
        education_text = this.educationList[i].label;
        break;
      }
    }

    let params = {
      // "subid" : "",
      // "graduation_school" : this.collegeItem.text,
      // "school_code" : this.collegeItem.code,
      // "start_date" : DateUtils.dateFormat(new Date(this.collegeStartdate),"yyyy-MM-dd"),
      // "end_date" : DateUtils.dateFormat(new Date(this.collegeEnddate),"yyyy-MM-dd"),
      // "education" : this.educationItems,//学历
      // "education_text" :education_text,
      // "major_status" : this.majorItem.code,
      // //major_name :  this.major.name,
      // "major_status_text" : this.majorItem.text,//专业
      "school_code": this.collegeItem.code,
      "graduation_school": this.collegeItem.text,
      "start_date": DateUtils.dateFormat(new Date(this.collegeStartdate), "yyyy-MM-dd"),
      "end_date": DateUtils.dateFormat(new Date(this.collegeEnddate), "yyyy-MM-dd"),
      "education": this.educationItems,//学历
      "education_text": education_text,
      "major_status": this.majorItem.code,
      "major_name": this.majorItem.text,
      "source_major": this.majorItem.relation_code,
      "source_major_text": this.majorItem.relation_desc,
      // "description": "string",
    };

    this.service.add(params);
  }

  submitDone() {
    this.router.navigateByUrl('userskill');
  }

}
