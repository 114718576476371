import { Subscription } from 'rxjs';
import {Api} from "../provider/Api";


export class BaseService {

    public subscription : Subscription;
    public page : any;
    public http: any;

    constructor(
      public api ?: Api,
    ){
        
    }

    init(page : any){
        this.subscription = new Subscription();
        this.page = page;
    }

    destroy(){
       if(this.subscription){
            this.subscription.unsubscribe();
        }
    }

}
