import {ChatMessage} from './../ChatMessage';
import {Api} from './../Api';
import {Events} from 'ionic-angular';
import {Config} from './../Config';
import {Injectable} from '@angular/core';
import {DateUtils} from './../../provider/utils/DateUtils';
import {Constants} from "../Constants";
import {StorgeUtils} from "./StorageUtils";

/**
 * 消息处理工具
 */
@Injectable()
export class ChatUtils {

  config: Config;

  dialogListDB: any;
  dialogDB: any;

  constructor(public api: Api,
              public events: Events,
              public local: StorgeUtils,
  ) {

    this.config = Config.getInstance();
  }

  /**
   * 统计所有消息未读数
   */
  initUnReadTotal() {
    console.log('this.config.dialogList',this.config.dialogList)
    this.config.unReadTotal = 0;
    if (this.config.dialogList) {
      for (let i = 0; i < this.config.dialogList.length; i++) {
        this.config.unReadTotal += this.config.dialogList[i].unread_count;
      }

      if (this.config.unReadTotal < 0) {
        this.config.unReadTotal = 0;
      }
      console.log( this.config.unReadTotal);

    }
  }

  /**
   * 收到极光消息
   *
   */
  messageReceiveUpdate(jmessage: any, res: any) {
    /**
     * 对话列表
     */
    let current_dlid;

    let isExist = false;//判断会话是否已经存在

    let messageType: any;
    let messageContent: any;

    if (jmessage.type && jmessage.content) {
      messageType = jmessage.type;
      messageContent = jmessage.content;
    } else {
      messageType = "text";
      messageContent = jmessage.text;
    }

    console.log(this.config.dialogList);


    if (this.config.dialogList) {
      for (let i = 0; i < this.config.dialogList.length; i++) {
        if (this.config.dialogList[i].dialogue_jmusername == jmessage.toUserName) {
          if (messageType == ChatMessage.SHIELD) {
            this.updateDialogueShield(this.config.dialogList[i].dlid, messageContent, false);
          }
          this.config.dialogList[i].last_message_type = messageType;
          this.config.dialogList[i].last_message = this.formatMessageContent(messageType, messageContent);
          this.config.dialogList[i].last_message_time = DateUtils.dateFormat(new Date(jmessage.dateTime), 'yyyy-MM-ddTHH:mm:ss+08:00');
          current_dlid = this.config.dialogList[i].dlid;
          if (this.config.current_dialog_user_dlid != current_dlid) {
            this.config.dialogList[i].unread_count += 1;
          }
          else if (!res) {
            this.config.dialogList[i].unread_count += 1;
          }

          isExist = true;
          break;
        }
      }

      /**
       * 如果会话已经存在，直接更新内容
       * 如果会话不存在，获取对话信息
       */
      if (isExist) {
        if (this.config.sender_jmusername == jmessage.toUserName) {
          console.log(jmessage);
          this.events.publish("jmessage:update", this.getChatMessageByJM(jmessage));
        }
      } else {
        //新的会话
        this.api.getChatDialogue(jmessage.toUserName)
          .subscribe((res: any) => {
            console.log(res);
            res.last_message = this.formatMessageContent(res.last_message_type, res.last_message);
            this.config.dialogList.push(res);
            current_dlid = res.dlid;
            this.events.publish("jmessage:update", this.getChatMessageByJM(jmessage));
          }, err => {
            console.log(err.msg);
          });
      }

    } else {
      console.log('this.config.dialogList',this.config.dialogList);
      this.api.getChatDialogue(jmessage.toUserName)
        .subscribe((res: any) => {
          console.log(res);
          let list = new Array();
          res.last_message = this.formatMessageContent(res.last_message_type, res.last_message);
          list.push(res);
          this.config.dialogList = list;
          this.events.publish("jmessage:update", this.getChatMessageByJM(jmessage));
          console.log(this.config.dialogList)
        }, err => {
          console.log(err.msg);
        });
    }

    this.config.dialogList = this.transform(this.config.dialogList, "last_message_time", true);//重新排序
    console.log(this.config.dialogList);
    this.initUnReadTotal();
    this.events.publish("jmessage:chatUnreadList",this.config.unReadTotal);
  }

  /**
   * 数组去除重复元素
   * 根据id判断
   * @param list
   */
  unique(list: any) {
    list.sort(); //先排序
    let res = [list[0]];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id !== res[res.length - 1].id) {
        res.push(list[i]);
      }
    }
    return res;
  }


  /**
   * 根据极光user id 获取对话dlid
   * @param jm_user_name
   */
  getDialogIdByJmUserId(jm_user_name: any) {
    let isExist = false;
    return new Promise(async resolve => {
      let chat_list = await this.dialogListDB.find();
      let list = new Array();

      for (let i = 0; i < chat_list.length; i++) {
        list.push(JSON.parse(chat_list[i].content));
      }

      if (list) {
        isExist = true;
      }

      if (isExist) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].dialogue_jmusername == jm_user_name) {
            resolve(list[i].dlid);
          }
        }
      } else {
        this.api.getChatDialogue(jm_user_name)
          .subscribe((res: any) => {
            console.log(res);
            resolve(res.dlid);
          }, err => {
            console.log(err.msg);
          });
      }

    });
  }

  /**
   * 根据极光信息，格式化为本地信息
   * @param jmessage
   */
  getChatMessageByJM(jmessage: any): ChatMessage {
    let messageType: any;
    let messageContent: any;
    //console.log("getChatMessageByJM");
    //console.log(jmessage.extras);
    if (jmessage && jmessage.type) {
      messageType = jmessage.type;
      messageContent = jmessage.content;
    } else {
      messageType = "text";
      messageContent = jmessage.text;
    }

    let chatMessage = new ChatMessage();
    chatMessage.type = messageType;
    chatMessage.content = messageContent;
    chatMessage.toUserName = jmessage.toUserName;
    if (jmessage.toUserName == this.config.jm_login_user) {
      chatMessage.direct = "to";
    } else {
      chatMessage.direct = "from";
    }

    chatMessage.dateTime = DateUtils.dateFormat(new Date(jmessage.dateTime), 'yyyy-MM-ddTHH:mm:ss+08:00');

    return chatMessage;
  }

  /**
   * 更新消息内容列表
   * @param dlid
   * @param chatMessage
   */
  updateChatList(dlid: any, chatMessage: ChatMessage) {
    //ChatDetailsPage中接收处理消息
    this.events.publish("jmessage:update", dlid);
  }

  /**
   * 发送消息
   * @param id 对话列表 dlid
   * @param params ChatMessage
   */
  messageSendUpdate(id: any, params: ChatMessage) {
    if (this.config.dialogList) {
      let is_exist = false;
      for (let i = 0; i < this.config.dialogList.length; i++) {
        if (this.config.dialogList[i].dlid == id) {
          this.config.dialogList[i].last_message_type = params.type;
          this.config.dialogList[i].last_message = this.formatMessageContent(params.type, params.content);
          this.config.dialogList[i].last_message_time = params.dateTime;
          is_exist = true;
          break;
        }
      }

      if (is_exist) {
        this.config.dialogList = this.transform(this.config.dialogList, "last_message_time", true);
        console.log("对话列表");
        //对话列表已存在，但对话不存在
      } else {
        this.api.getChatDialogue(params.toUserName)
          .subscribe((res: any) => {
            console.log(res);
            res.last_message = this.formatMessageContent(res.last_message_type, res.last_message);
            this.config.dialogList.push(res);
            this.config.dialogList = this.transform(this.config.dialogList, "last_message_time", true);
          }, err => {
            console.log(err.msg);
          });
      }
      //对话列表不存在
    } else {
      this.api.getChatDialogue(params.toUserName)
        .subscribe((res: any) => {
          console.log(res);
          let list = new Array();

          res.last_message = this.formatMessageContent(res.last_message_type, res.last_message);
          list.push(res);

          this.config.dialogList = this.transform(list, "last_message_time", true);
        }, err => {
          console.log(err.msg);
        });
    }
  }

  /**
   * 更新对话列表
   */
  updateDialogList() {
    this.config.dialogList = this.transform(this.config.dialogList, "last_message_time", true);

    //在chat中订阅
    this.events.publish("jmessage:list", "list");
  }

  /**
   * 消息内容格式化
   * 对话列表最后一条信息和通知栏显示信息
   * @param type
   * @param content
   */
  formatMessageContent(type: any, content: any): string {
    let message: string = "";
    switch (type) {
      case ChatMessage.TEXT:
        message = content;
        break;
      case ChatMessage.POSITION:
        message = "[职位信息]"
        break;
      case ChatMessage.TALENT:
        message = "[职位信息]"
        break;
      case ChatMessage.RESUME:
        message = "[简历信息]"
        break;
      case ChatMessage.WECHAT:
        message = "[微信号]";
        break;
      case ChatMessage.IMAGE:
        message = "[图片]";
        break;
      case ChatMessage.INVITATION:
        message = "[面试邀请]";
        break;
      case ChatMessage.REPLY:
        message = "[面试回复]";
        break;
      case ChatMessage.OFFER:
        message = "[录用消息]";
        break;
      case ChatMessage.SHIELD:
        message = "[对话屏蔽]";
        break;
      case ChatMessage.MILKROUND:
        message = "[招聘会]";
        break;
      case ChatMessage.CAMPUSTALK:
        message = "[宣讲会]";
        break;
      case ChatMessage.MULTITEXT:
        message = "[通知]";
        break;
      default:
        message = "[消息]";
        break;
    }

    return message;
  }

  /**
   * 屏蔽对话
   * @param dlid
   * @param is_shield
   * @isActive 主动屏蔽 被屏蔽
   */
  updateDialogueShield(dlid: any, is_shield: boolean, isActive = true) {

    if (this.config.dialogList) {
      for (let i = 0; i < this.config.dialogList.length; i++) {
        if (this.config.dialogList[i].dlid == dlid) {
          if (isActive) {
            this.config.dialogList[i].is_active_shield = is_shield;

            this.config.dialogList[i].last_message_type = ChatMessage.SHIELD;
            this.config.dialogList[i].last_message = this.formatMessageContent(ChatMessage.SHIELD, "");
            this.config.dialogList[i].last_message_time = DateUtils.dateFormat(new Date(), 'yyyy-MM-ddTHH:mm:ss+08:00');
          } else {
            this.config.dialogList[i].is_shielded = is_shield;
          }

          break;
        }
      }
    }

  }

  /**
   * 进入对话时，更新对话列表中的未读数
   * @param id
   */
  updateRead(id: any) {
    if (this.config.dialogList) {
      for (let i = 0; i < this.config.dialogList.length; i++) {
        if (this.config.dialogList[i].dlid == id) {
          if (this.config.unReadTotal > 0) {
            this.config.unReadTotal -= this.config.dialogList[i].unread_count;
          } else {
            this.config.unReadTotal = 0;
          }
          this.config.dialogList[i].unread_count = 0;
          break;
        }
      }
      console.log("updateRead");
      console.log(this.config.unReadTotal);
      if (this.config.dialogList) {
        for (let i = 0; i < this.config.dialogList.length; i++) {
          if (this.config.dialogList[i].dlid == id) {
            this.config.dialogList[i].unread_count = 0;
            break;
          }
        }
      }
    }
    this.local.set(Constants.LocalStorage.unreadList, this.config.unReadTotal);
  }

  /**
   * 置顶
   * @param id
   * @param is_top
   */
  setTopChat(id: any, is_top: boolean) {
    for (let i = 0; i < this.config.dialogList.length; i++) {
      if (this.config.dialogList[i].dlid == id) {
        this.config.dialogList[i].is_top = is_top;
        break;
      }
    }
  }

  /**
   * 对话列表排序 按最新对话时间
   * @param value
   * @param field
   * @param desc
   */
  transform(value: Array<any>, field: string, desc: boolean = true): any {
    if (field === null || field === '') return value;

    if (!desc) desc = false;
    let descNumber = desc ? 1 : -1;

    if (value instanceof Array) {
      return value.sort((l, r): number => {
        if (this.isDate(l[field]) < this.isDate(r[field])) return (1 * descNumber);
        if (this.isDate(l[field]) > this.isDate(r[field])) return (-1 * descNumber);
        return 0
      });
    }
    return 0;
  }

  isDate(field: any): any {
    if (isNaN(Date.parse(field))) {
      return field;
    }
    return Date.parse(field);
  }
}
