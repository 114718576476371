import {Injectable} from '@angular/core';
/*
 * 对Storage进行二次封装，增加可维护性, 或方便添加我们自己的钩子代码
 */
@Injectable()
export class StorgeUtils {

    public storage:any;
    
    constructor() { 
      if (!localStorage) {
        throw new Error('Current browser does not support Local Storage');
      }
      this.storage = localStorage;
    }

    public get(key:string):string {
        return this.storage[key] || false;
    }

    public set(key:string, value:any):void {
        this.storage[key] = value;
    }

    public remove(key:string):any {
        this.storage.removeItem(key);
    }

    public clear(){
        this.storage.clear();
    }
}