import { Router } from '@angular/router';
import { LoginPositionSkillService } from './login-position-skill.service';
import { BasePage } from './../base/BasePage';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {Constants} from "../../provider/Constants";
import {StorgeUtils} from "../../provider/utils/StorageUtils";
import {DateUtils} from "../../provider/utils/DateUtils";
import { Api } from './../../provider/Api';

@Component({
  selector: 'app-login-position-skill',
  templateUrl: './login-position-skill.component.html',
  styleUrls: ['./login-position-skill.component.css']
})
export class LoginPositionSkillComponent extends BasePage implements OnInit {

  listItem = new Array();
  totalSelect = new Array();

  constructor(public messageService: MessageService,
              public service : LoginPositionSkillService,
              public local: StorgeUtils,
              public router : Router,
              public api : Api) {
    super(messageService);
  }

  ngOnInit() {
    this.service.init(this);
    this.service.get();
  }

  setList(res : any){
    this.listItem = res;

    for(let i = 0;i < this.listItem.length;i++){
      this.totalSelect[i] = 0;
      for(let value of this.listItem[i].contrast_list){
        value.selected = false;
      }
    }
  }

  select(item : any, key : any){
    item.selected = !item.selected;

    if(!this.countSelect(key)){
      item.selected = false;
      this.showMsg("只能选择5项技能");
    }
  }

  //每一个职位只能选择5项技能
  countSelect(key : any) : boolean{
    let num = 0;
    for(let i = 0;i < this.listItem[key].contrast_list.length;i++){
      if(this.listItem[key].contrast_list[i].selected){
        num++;
      }
    }

    if(num > 5){
      this.totalSelect[key] = 5;
    }else{
      this.totalSelect[key] = num;
    }

    console.log(num);
    if(num < 6){
      return true;
    }

    return false;
  }

  doSubmit(){

    let listData = JSON.parse(JSON.stringify(this.listItem));//this.listItem.slice();//数组复制
    this.config.proSkills = JSON.parse(JSON.stringify(this.listItem));

    //验证每一个职位都选择技能
    let hasSelected = false;
    for(let item of this.listItem){
      hasSelected = false;
      for(let value of item.contrast_list){
        if(value.selected){
          hasSelected = true;
          break;
        }
      }

      if(!hasSelected){
        break;
      }
    }

    console.log(hasSelected);

    if(hasSelected){

      for(let k = 0; k < this.listItem.length; k++){
        listData[k].contrast_list = [];
        this.config.proSkills[k].contrast_list = [];

        for(let i = 0; i < this.listItem[k].contrast_list.length; i++){
          if(this.listItem[k].contrast_list[i].selected){
            listData[k].contrast_list.push(this.listItem[k].contrast_list[i].code);
            this.config.proSkills[k].contrast_list.push(this.listItem[k].contrast_list[i]);
          }
        }
      }

      console.log(this.config.proSkills);
        this.service.submit(JSON.stringify(listData));
    }else{
      this.showMsg("请为每个职位设定技能");
    }
  }

  done(){
    //进入首页
    this.getResume();
    this.config.isLogin = true;
    let login_action =  this.local.get('login_action');
    if(login_action){
      this.router.navigateByUrl('/workspace/myCenter-signList');
      this.local.remove('login_action');
      return
    }
    this.router.navigateByUrl('workspace');
  }
  getResume() {
    this.api.getResume()
      .subscribe(async (res: any) => {
        console.log("getResume");
        console.log(res);

        if (res && res.resumeid) {
          this.config.resume_id = res.resumeid;
          this.config.resume_number = res.resume_number;
          //入职时间
          this.config.entryTime.code = res.entry;
          this.config.entryTime.text = res.entry_text;

          this.local.set(Constants.LocalStorage.ResumeId, res.resumeid);
          this.local.set(Constants.LocalStorage.ResumeNumber, res.resume_number);

          this.config.workExperiences = res.work_experiences;//工作经历
          this.config.training = res.training_experiences;//培训经历
          this.config.certificates = res.certificates;//证书
          this.config.opus = res.works;//作品
          this.config.project = res.project_experiences;//项目/比赛经历
          this.config.School_Position = res.school_positions ; // 在校职务
          this.config.Languages_Get = res.languages; // 语言能力
          this.config.job_status = res.talent_base.job_status; // 求职意向
          this.config.job_status_text = res.talent_base.job_status_text; // 求职意向
          this.config.self_evaluates = res.self_evaluates; // 自我描述

          //判断是否第一次注册学校

            this.config.education = res.education_backgrounds;//教育经历
            this.config.user.name = res.talent_base.name;
            this.config.user.sex = res.talent_base.gender;
            if (res.talent_base.last_online_time) {
              this.config.user.lastLoginTime = DateUtils.dateFormat(new Date(res.talent_base.last_online_time), 'yyyy年MM月dd');
            }

            if (res.talent_base.logo) {
              this.config.user.avatar = res.talent_base.logo;
            } else {
              if (res.talent_base.gender == "01") {
                this.config.user.avatar = this.config.maleDefaultAvatar;
              } else if (res.talent_base.gender == "02") {
                this.config.user.avatar = this.config.femaleDefaultAvatar;
              } else {
                this.config.user.avatar = this.config.userDefaultAvatar;
              }
            }
            this.config.user.wechat = res.talent_base.wechat;
            this.config.user.email = res.talent_base.email;
            this.config.user.school = res.education_backgrounds[0].graduation_school;
            this.config.user.schoolCode = res.education_backgrounds[0].school_code;
            this.config.user.major = res.education_backgrounds[0].major_status_text;


            this.config.userMobile = res.talent_base.mobile;
            this.config.studentId = res.talent_base.no;
            this.config.collegeId = res.talent_base.collegeid;


            this.local.set(Constants.LocalStorage.CollegeId, res.talent_base.collegeid);

            this.local.set(Constants.LocalStorage.User, JSON.stringify(this.config.user));

            this.config.jobTarget = res.job_target;//职位筛选条件

            this.config.resumeLevel = res.level;//简历完整度
            /*
            if(res.job_target.work_city[0]){
              this.config.user.targetCity = res.job_target.work_city[0].text;
              this.config.user.targetCityCode = res.job_target.work_city[0].code;
            }*/

            // 判断是否第一次注册技能


        }
      }, err => {
        console.log(err);
        if (err.error) {
          if (err.error.status == 403 || err.error.status == 401) {
            } else if (err.error.status == 400) {
            this.router.navigateByUrl('login');
          }
        } else {
          this.router.navigateByUrl('login');
        }
      })
  }
}
