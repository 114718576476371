import { LoginUserProfileService } from './login-user-profile.service';
import { BasePage } from './../base/BasePage';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {SelectItem} from 'primeng/api';
import { Router } from '@angular/router';
import {StorgeUtils} from "../../provider/utils/StorageUtils";
import {Constants} from "../../provider/Constants";
import {DateUtils} from "../../provider/utils/DateUtils";
import html2canvas from 'html2canvas';

declare var $: any;
declare var window;

@Component({
  selector: 'app-login-user-profile',
  templateUrl: './login-user-profile.component.html',
  styleUrls: ['./login-user-profile.component.css']
})
export class LoginUserProfileComponent extends BasePage implements OnInit {

  name = '';
  gender = '01';
  sex: SelectItem[];
  noSubmit: boolean = true;
  birthDate: string;

  cn: any; // 日期
  ProvinceList : SelectItem[] = new Array(); // 省
  CityList : SelectItem[] = new Array();  // 市
  CountyList: SelectItem[] = new Array();// 县
  selectProvince: string; // 省
  selectCity: string; // 市
  selectCounty: string; // 县
  address: any;
  userLogo: any;


  constructor(public messageService: MessageService,
              public router : Router,
              public service : LoginUserProfileService,
              public local: StorgeUtils
              ) {
    super(messageService);
  }

  ngOnInit() {
    this.config.user.avatar = this.config.userDefaultAvatar;
    this.service.init(this);
    this.hideLoading();
    this.sex =  [
      {label:'男', value:"01"},
      {label:'女', value:"02"},
    ];
    this.cn = {
      firstDayOfWeek: 0,
      dayNames: ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
      dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
      monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
      monthNamesShort: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      today: '今天',
      clear: '清除'
    };

    this.address = JSON.parse(this.local.get(Constants.LocalStorage.RegionData));
    for(let i in this.address) {
      if(this.address[i].level == 1) {
        let data = {label: this.address[i].text, value: this.address[i].code};
        this.ProvinceList.push(data)
      } else if(this.address[i].level == 2) {
        let data = {label: this.address[i].text, value: this.address[i].code};
        this.CityList.push(data);
      } else if(this.address[i].level == 3) {
        let data = {label: this.address[i].text, value: this.address[i].code};
        this.CountyList.push(data);
      }
    }


    if(!this.selectCounty){
      this.selectProvince = this.ProvinceList[0].value;
      this.getCity(this.ProvinceList[0].value);
      this.getDistrict(this.CityList[0].value);
    }

    this.popopInit();
  }

  myUploader(event,uploader){
    let imgData = event.files[0];

    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);

      var that = this;
      reader.onloadend = function (e) {
        let imageValue = reader.result;
        that.uploadAvatar(imageValue);
        uploader.clear();
      };
    }
  }

  uploadAvatar(imageData : any){
    imageData = imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    let imageValue = imageData.replace(/^(data:image)\/png;base64,/g,'');

    let params = { "value" :  imageValue };
    this.service.updateAvatar(params);
  }


  next(){
    if (this.config.user && this.config.user.avatar && this.config.user.avatar.indexOf('user-default-avatar.png') !== -1) {
      return this.showMsg("请上传头像");
    }

    if(this.name.trim()){
        //过滤emoji表情
        let ranges = [
          '\ud83c[\udf00-\udfff]',
          '\ud83d[\udc00-\ude4f]',
          '\ud83d[\ude80-\udeff]'
        ];

        this.name = this.name.replace(new RegExp(ranges.join('|'), 'g'), '');

        let uPattern = new RegExp("^([\u4E00-\uFA29]|[a-zA-Z]){1,10}$");///^[\u4e00-\u9fa5]|[a-zA-Z]{1}$/;//汉字或者英文字母

        if(uPattern.test(this.name)){
          if(this.birthDate)  {
            this.birthDate = DateUtils.dateFormat(new Date(this.birthDate), "yyyy-MM-dd");
            if(this.selectCounty) {
              let params= {
                "name": this.name,
                "gender": this.gender,
                "birthdate": this.birthDate,
                "native_place": this.selectCounty,
              };
              if(this.noSubmit){
                this.noSubmit = false;
                let noLogo = this.local.get('noLogo');
                if (noLogo) {
                  this.service.upDateInfo(params);
                } else {
                  this.service.registerInfo(params);
                }
              }
            } else {
              this.showMsg("请选择籍贯");
            }

          } else {
            this.showMsg("请选择出生日期");
          }

        }else{
          this.showMsg("请输入中文或字母");
        }
    }else{
      this.showMsg("请输入真实姓名!");
    }
  }

  done(){
    this.router.navigateByUrl('usercollege');
  }

   // 省
  setProvince(e) {
    this.CityList = new Array();
    for(let i in this.address){
      if(this.address[i].code.slice(0,2) == e && this.address[i].level == 2) {
        let data = {label: this.address[i].text, value: this.address[i].code};
        this.CityList.push(data)
      }
    }
    this.setCity(this.CityList[0].value)
  }

  // 市
  setCity(e) {
    this.CountyList = new Array();
    for(let i in this.address){
      if(this.address[i].parentcode == e && this.address[i].level == 3) {
        let data = {label: this.address[i].text, value: this.address[i].code};
        this.CountyList.push(data)
      }
    }
    this.selectCounty = this.CountyList[0].value;
  }

  // 县
  setCounty(e) {
    this.selectCounty = e;
  }

  getCity(code: any) {
    this.CityList = new Array();
    this.address.forEach((val) => {
      if (val.code.substring(0, 2) == code.substring(0, 2) && val.level == 2) {
        let data = {label: val.text, value: val.code};
        this.CityList.push(data);
      }
    });
    this.selectCity = this.CityList[0].value
  }

  getDistrict(code: any) {
    this.CountyList = new Array();
    this.address.forEach((val) => {
      if (val.parentcode == code) {
        let data = {label: val.text, value: val.code};
        this.CountyList.push(data);
      }
    });
    this.selectCounty = this.CountyList[0].value;
  }



  //弹出框水平垂直居中
  popopInit() {
    console.log('11111111111111111')
    var win_height = $(window).height();
    var win_width = $(window).width();
    if (win_width <= 768) {
      $(".tailoring-content").css({
        "top": (win_height - $(".tailoring-content").outerHeight()) / 2,
        "left": 0
      });
    } else {
      $(".tailoring-content").css({
        "top": (win_height - $(".tailoring-content").outerHeight()) / 2,
        "left": (win_width - $(".tailoring-content").outerWidth()) / 2
      });
    }

    //弹出图片裁剪框
    $("#replaceImg").on("click",function () {
      $(".tailoring-container").toggle();
    });

    //cropper图片裁剪
    $('#tailoringImg').cropper({
      aspectRatio: 1/1,//默认比例
      preview: '.previewImg',//预览视图
      guides: false,  //裁剪框的虚线(九宫格)
      autoCropArea: 0.5,  //0-1之间的数值，定义自动剪裁区域的大小，默认0.8
      dragCrop: true,  //是否允许移除当前的剪裁框，并通过拖动来新建一个剪裁框区域
      movable: true,  //是否允许移动剪裁框
      resizable: true,  //是否允许改变裁剪框的大小
      zoomable: false,  //是否允许缩放图片大小
      mouseWheelZoom: false,  //是否允许通过鼠标滚轮来缩放图片
      touchDragZoom: true,  //是否允许通过触摸移动来缩放图片
      rotatable: true,  //是否允许旋转图片
      crop: function(e) {
        // 输出结果数据裁剪图像。
      }
    });
//旋转
    $(".cropper-rotate-btn").on("click",function () {
      $('#tailoringImg').cropper("rotate", 45);
    });
//复位
    $(".cropper-reset-btn").on("click",function () {
      $('#tailoringImg').cropper("reset");
    });
//换向
    var flagX = true;
    $(".cropper-scaleX-btn").on("click",function () {
      if(flagX){
        $('#tailoringImg').cropper("scaleX", -1);
        flagX = false;
      }else{
        $('#tailoringImg').cropper("scaleX", 1);
        flagX = true;
      }
      flagX != flagX;
    });
  }

  save() {
    let that = this;
    const imgLg = document.getElementById('previewImg');
    console.log(imgLg, 'imgLg');
    html2canvas(imgLg, {width: 200, height: 200, useCORS: true}).then(canvas => {
      that.uploadAvatar(canvas.toDataURL('image/png'));
      $(".tailoring-container").toggle();
    });
  }

  //图像上传
  selectHeadImg(event) {
    console.log(event.target.files,"file.files")
    if (!event.target.files || event.target.files.length == 0){
      return;
    }

    var reader = new FileReader();
    reader.onload = function (evt) {
      var replaceSrc = evt.target.result;
      //更换cropper的图片
      $('#tailoringImg').cropper('replace', replaceSrc,false);//默认false，适应高度，不失真
    }
    reader.readAsDataURL(event.target.files[0]);

  }

//关闭裁剪框
  closeTailor() {
    $(".tailoring-container").toggle();
  }

  // 头像上传成功
  setUserAvatar(res){
    this.config.user.avatar = res.value;
  }


}
