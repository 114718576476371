
import { Constants } from './../../provider/Constants';
import { StorgeUtils } from './../../provider/utils/StorageUtils';
import { Config } from './../../provider/Config';
import { Validators } from './../../provider/utils/Validators';
import { Api } from './../../provider/Api';
import { BaseService } from './../../services/BaseService';
import { Injectable } from '@angular/core';
import {XiaoMiMessage} from "../../provider/utils/XiaoMiMessage";

@Injectable()
export class LoginUserProfileService extends BaseService{

    constructor(public api : Api,
                public local : StorgeUtils,
                public im: XiaoMiMessage
                ){
        super();
    }

    /**
     * 创建账户
     * 获取accound_id
     */
    registerInfo(params : any){
        if(Validators.isEmpty(params.name)){
            this.page.showMsg("请输入真实姓名!");
            this.page.setNameFocus();
        }else{

            this.subscription.add(
                this.api.registerUserInfo(params)
                        .subscribe((res: any) => {
                            console.log(res);

                            Config.getInstance().account_id = res.accountid;
                            Config.getInstance().resume_id = res.resumeid;

                            Config.getInstance().resume_number = res.resume_number;
                            Config.getInstance().jm_login_user = res.jm_username;
                            Config.getInstance().jm_login_pwd = res.jm_password;

                            this.local.set(Constants.LocalStorage.AccountId,res.accountid);
                            this.local.set(Constants.LocalStorage.ResumeId,res.resumeid);
                            this.local.set(Constants.LocalStorage.ResumeNumber,res.resume_number);
                            this.local.set(Constants.LocalStorage.JmUser,res.jm_username);
                            this.local.set(Constants.LocalStorage.JmPwd,res.jm_password);

                            this.local.set(Constants.LocalStorage.User,Config.getInstance().user);

                            Config.getInstance().user.name = params.name;
                            Config.getInstance().user.sex = params.gender;

                            this.jmLogin();

                            //
                            this.page.done();
                        }, err => {
                            console.log(err);
                            let errorMsg = JSON.parse(err.error._body);
                            this.page.showMsg(errorMsg.Errmsg);
                            //this.page.showMsg(err.msg);
                            // this.page.hideLoading();
                        })
            );
        }
    }
    upDateInfo(params : any){
        if(Validators.isEmpty(params.name)){
            this.page.showMsg("请输入真实姓名!");
            this.page.setNameFocus();
        }else{

            this.subscription.add(
                this.api.talentUpdateUserInfo(params)
                        .subscribe((res: any) => {
                            console.log(res);

                            Config.getInstance().account_id = res.accountid;
                            Config.getInstance().resume_id = res.resumeid;
                            Config.getInstance().resume_number = res.resume_number;
                            Config.getInstance().jm_login_user = res.jm_username;
                            Config.getInstance().jm_login_pwd = res.jm_password;

                            this.local.set(Constants.LocalStorage.AccountId,res.accountid);
                            this.local.set(Constants.LocalStorage.ResumeId,res.resumeid);
                            this.local.set(Constants.LocalStorage.ResumeNumber,res.resume_number);
                            this.local.set(Constants.LocalStorage.JmUser,res.jm_username);
                            this.local.set(Constants.LocalStorage.JmPwd,res.jm_password);

                            this.local.set(Constants.LocalStorage.User,Config.getInstance().user);

                            Config.getInstance().user.name = params.name;
                            Config.getInstance().user.sex = params.gender;

                            this.jmLogin();

                            //
                            this.page.done();
                        }, err => {
                            //console.log(err);
                            let errorMsg = JSON.parse(err.error._body);
                            this.page.showMsg(errorMsg.Errmsg);
                            //this.page.showMsg(err.msg);
                            // this.page.hideLoading();
                        })
            );
        }
    }
    updateAvatar(params : any){
        this.page.showLoading();
         this.subscription.add(
            this.api.updateUserAvatar(params)
                    .subscribe((res: any) => {
                        console.log(res);

                        if(res){
                            this.page.setUserAvatar(res);
                        }

                        this.page.hideLoading();
                    }, err => {
                        let errorMsg = JSON.parse(err.error._body);
                        this.page.showMsg(errorMsg.Errmsg);
                        //this.page.showMsg(err.msg);
                        this.page.hideLoading();
                    })
        );
    }

    jmLogin(){
        if(Config.getInstance().jm_login_user && Config.getInstance().jm_login_pwd){
          let params = {
            'username': Config.getInstance().jm_login_user,
            'password': Config.getInstance().jm_login_pwd
          };
          console.log("jmLogin()");
          console.log(params);

        //   this.api.getJmInitData()
        //           .subscribe((res: any) => {
        //             console.log("获取极光web im初始化参数")
        //             console.log(res);

        //             this.im.login(Config.getInstance().jm_login_user);


        //             // this.jm.login(res,params).then(res => {
        //             //   if(res == 'login'){
        //             //     console.log("极光Web IM登录成功");
        //             //   }else{
        //             //     console.log(res);
        //             //
        //             //     setTimeout(() => {
        //             //       this.jmLogin();
        //             //     },1000 * 5)
        //             //   }
        //             // });


        //           });
        }else{
          this.api.getAccount()
              .subscribe(async (res: any) => {
                  console.log(res);
                  if(res.accountid){
                      Config.getInstance().jm_login_user = res.jm_username;
                      Config.getInstance().jm_login_pwd = res.jm_password;

                      this.local.set(Constants.LocalStorage.JmUser,res.jm_username);
                      this.local.set(Constants.LocalStorage.JmPwd,res.jm_password);

                      this.jmLogin();
                  }
              }, err => {
                  console.log(err);
              })
        }
    }
}
