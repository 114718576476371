import {Api} from './../provider/Api';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Constants} from './../provider/Constants';
import {StorgeUtils} from './../provider/utils/StorageUtils';
import {Config} from './../provider/Config';
import {Component, OnInit,OnDestroy} from '@angular/core';
import * as addSeconds from 'date-fns/add_seconds';
import {Location} from '@angular/common';
import {Events} from 'ionic-angular';
import {DateUtils} from "../provider/utils/DateUtils";
import {XiaoMiMessage} from "../provider/utils/XiaoMiMessage";
import {WorkspaceService} from "./workspace/workspace.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent extends WorkspaceService implements OnInit,OnDestroy {

  config: Config;
  formUrl: any;
  queryParams: any;
  urlParams: any;


  constructor(public router: Router,
              private activatedRoute: ActivatedRoute,
              public local: StorgeUtils,
              public location: Location,
              public api: Api,
              public events: Events,
              public im: XiaoMiMessage,
              public service: WorkspaceService
  ) {
    super(api,local);
  }

  ngOnInit() {

    console.log("AppComponent ngOnInit");

    this.config = Config.getInstance();
   //  ActivatedRoute，当前激活路由对象，主要用于保存路由，获取路由传递的参数。
   // this.queryParams =  this.activatedRoute.queryParams.subscribe(queryParams => {
   //    console.log("activatedRoute queryParams",queryParams);
   //    if (queryParams.rs) {
   //      this.local.set(Constants.LocalStorage.RegisterSource, queryParams.rs);
   //    }
   //
   //    if (queryParams.ps) {
   //      this.local.set(Constants.LocalStorage.ProjectSource, queryParams.ps);
   //    }
   //
   //
   //  });

    /*
     * 此处跳转兼容处理
     * 跳转有参数 修改跳转地址 跳转中间页
     * 没参数走正常逻辑
     * */
    if(JSON.stringify(this.checkUserLogin()) != '{}') {
      let url = document.location.toString();
      if(url.indexOf('workspace/myCenter-resume') > -1){
        window.location.href = url.replace('workspace/myCenter-resume','routerTransfer')
      } else if( url.indexOf('workspace/myCenter-studentInfo') > -1) {
        window.location.href = url.replace('workspace/myCenter-studentInfo','routerTransfer')
      } else if( url.indexOf('routerTransfer') > -1) {
        window.location.href = url.replace('routerTransfer', 'routerTransfer')
      }else if(url.indexOf('login') < 0) {
        this.checkLogin();
      }
    } else {
      this.checkLogin();
    }

    /*
    * 如果当前路径不是跳转过来路径 进行正常逻辑处理
    * 忽用路由监听等方式 路由注册方式不用 程序将重复执行
    * */
    // if(location.href.split('/#/')[1] && location.href.split('/#/')[1].split('?')[0] != 'routerTransfer') {
    //   this.checkLogin();
    // } else {
    //   console.log('Specifying a path jump does not do processing')
    // }


  }

  checkLogin() {
    // 判断token 抓取用户信息 存入全 局
    let token = this.local.get(Constants.LocalStorage.AccessToken);
    console.log("checkLogin token");
    if (token) {
      this.config.token = token;
      this.config.isLogin = true;
      let accountId = this.local.get(Constants.LocalStorage.AccountId);
      if (accountId) {
        this.config.account_id = accountId;
        this.config.jm_login_user = this.local.get(Constants.LocalStorage.JmUser);
        this.config.jm_login_pwd = this.local.get(Constants.LocalStorage.JmPwd);
        this.config.resume_id = this.local.get(Constants.LocalStorage.ResumeId);
        this.config.resume_number = this.local.get(Constants.LocalStorage.ResumeNumber);
        this.config.collegeId = this.local.get(Constants.LocalStorage.CollegeId);
        this.config.isLogin = true;
        this.getUserInfo();
      } else {
        this.checkAccount();
      }
    } else {
      this.local.clear();
      this.router.navigateByUrl('login');
    }
  }


  // 获取用户关联账户
  checkAccount() {
    this.api.getAccount()
      .subscribe((res: any) => {
        console.log("checkAccount");
        if (res.accountid) {
          this.config.account_id = res.accountid;
          console.log('this.config.account_id',this.config.account_id)
          this.config.jm_login_user = res.jm_username;
          this.config.jm_login_pwd = res.jm_password;

          this.local.set(Constants.LocalStorage.AccountId, res.accountid);
          this.local.set(Constants.LocalStorage.JmUser, res.jm_username);
          this.local.set(Constants.LocalStorage.JmPwd, res.jm_password);
          this.getUserInfo();
        } else {
          this.local.clear();
          this.router.navigateByUrl('login');
        }
      }, err => {
        //this.uiUtils.showToast(err.msg);
        console.log(err,'app login err');
        if (err.error.status == 403 || err.error.status == 401) {
          //this.rootPage = 'LoginStudentIdPage';
          //this.router.navigateByUrl('login');
          // this.local.clear();
          this.refreshToken();
        }
      })
  }

  getResume() {
    this.api.getResume()
      .subscribe(async (res: any) => {
        console.log("getResume");
        console.log(res);

        if (res && res.resumeid) {
          this.config.resume_id = res.resumeid;
          this.config.resume_number = res.resume_number;
          //入职时间
          this.config.entryTime.code = res.entry;
          this.config.entryTime.text = res.entry_text;

          this.local.set(Constants.LocalStorage.ResumeId, res.resumeid);
          this.local.set(Constants.LocalStorage.ResumeNumber, res.resume_number);

          this.config.workExperiences = res.work_experiences;//工作经历
          this.config.training = res.training_experiences;//培训经历
          this.config.certificates = res.certificates;//证书
          this.config.opus = res.works;//作品
          this.config.project = res.project_experiences;//项目/比赛经历
          this.config.School_Position = res.school_positions ; // 在校职务
          this.config.Languages_Get = res.languages; // 语言能力
          this.config.job_status = res.talent_base.job_status; // 求职意向
          this.config.job_status_text = res.talent_base.job_status_text; // 求职意向
          this.config.self_evaluates = res.self_evaluates; // 自我描述

          //判断是否第一次注册学校
          if (res.education_backgrounds && res.education_backgrounds.length > 0) {

            this.config.education = res.education_backgrounds;//教育经历
            this.config.user.name = res.talent_base.name;
            this.config.user.sex = res.talent_base.gender;
            if (res.talent_base.last_online_time) {
              this.config.user.lastLoginTime = DateUtils.dateFormat(new Date(res.talent_base.last_online_time), 'yyyy年MM月dd');
            }

            if (res.talent_base.logo) {
              this.config.user.avatar = res.talent_base.logo;
            } else {
              if (res.talent_base.gender == "01") {
                this.config.user.avatar = this.config.maleDefaultAvatar;
              } else if (res.talent_base.gender == "02") {
                this.config.user.avatar = this.config.femaleDefaultAvatar;
              } else {
                this.config.user.avatar = this.config.userDefaultAvatar;
              }
            }
            this.config.user.wechat = res.talent_base.wechat;
            this.config.user.email = res.talent_base.email;
            this.config.user.school = res.education_backgrounds[0].graduation_school;
            this.config.user.schoolCode = res.education_backgrounds[0].school_code;
            this.config.user.major = res.education_backgrounds[0].major_status_text;


            this.config.userMobile = res.talent_base.mobile;
            this.config.studentId = res.talent_base.no;
            this.config.collegeId = res.talent_base.collegeid;

            this.getCollege(this.config.collegeId);//获取学校Logo

            this.local.set(Constants.LocalStorage.CollegeId, res.talent_base.collegeid);

            this.local.set(Constants.LocalStorage.User, JSON.stringify(this.config.user));

            this.config.jobTarget = res.job_target;//职位筛选条件

            this.config.resumeLevel = res.level;//简历完整度
            /*
            if(res.job_target.work_city[0]){
              this.config.user.targetCity = res.job_target.work_city[0].text;
              this.config.user.targetCityCode = res.job_target.work_city[0].code;
            }*/

            // 判断是否第一次注册技能
            if (res.skills && res.skills.length > 0) {
              console.log('appres.skills',res.skills);

              this.config.skills = res.skills;//技能

              // 判断是否第一职位订阅
              //if(res.job_target.career.length > 0){

              // 判断是否选择职位
              // if (res.pro_skills && res.pro_skills.length > 0) {
                this.config.proSkills = res.pro_skills;

                let hasSelected = true;
                for (let item of this.config.proSkills) {
                  if (item.contrast_list.length == 0) {
                    hasSelected = false;
                  }
                }

                //判断专业（职位）技能
                // if (hasSelected) {
                  this.getRegion();
                  this.config.isLogin = true;
                  //已完成引导注册
                  /* resume */
                    if (this.location.path()) {
                      this.router.navigateByUrl(this.location.path());
                    } else {
                      this.router.navigateByUrl('/workspace/home');
                    }
                // } else {
                //   this.router.navigateByUrl('userpositionskill');
                // }

              // } else {
              //   this.router.navigateByUrl('userposition');
              // }

            } else {
              //注册技能
              this.router.navigateByUrl('userskill');
            }

          } else {
            //注册学校
            this.router.navigateByUrl('usercollege');
          }
        }
      }, err => {
        console.log(err);
        if (err.error) {
          if (err.error.status == 403 || err.error.status == 401) {
            this.refreshToken();
            } else if (err.error.status == 400) {
            this.router.navigateByUrl('login');
          }
        } else {
          this.router.navigateByUrl('login');
        }
      })
  }


  // 获取用户信息
  getUserInfo() {
    console.log('getUserInfo account_id',this.config.account_id);
    this.api.getUserInfo()
      .subscribe( (res: any) => {
        console.log("get user info",res);
        console.log('this.config.account_id',this.config.account_id);
        if (res.mobile_actived == false || !res.mobile) {
          this.router.navigateByUrl('bindingmobile');
        } else {
          this.config.resume_id = this.local.get(Constants.LocalStorage.ResumeId);
          this.config.resume_number = this.local.get(Constants.LocalStorage.ResumeNumber);
          if(this.local.get(Constants.LocalStorage.AccountId)) {
            this.config.account_id = this.local.get(Constants.LocalStorage.AccountId)
          }
          this.getResume();
          this.jmLogin();
          this.getAdv();
        }
      }, err => {
        this.local.clear();
        this.router.navigateByUrl('login');
      })
  }

  // 小米初始化
  jmLogin() {
    if (this.config.jm_login_user && this.config.jm_login_pwd) {
      let params = {
        'username': this.config.jm_login_user,
        'password': this.config.jm_login_pwd
      };
      console.log("jmLogin",params);

      // this.api.getJmInitData()
      //   .subscribe((res: any) => {
      //     console.log("获取极光web im初始化参数");
      //     console.log(res);
      //     this.im.login(this.config.jm_login_user)
      //   });
    } else {
      this.api.getAccount()
        .subscribe(async (res: any) => {
          console.log(res);
          if (res.accountid) {
            this.config.jm_login_user = res.jm_username;
            this.config.jm_login_pwd = res.jm_password;
            this.local.set(Constants.LocalStorage.JmUser, res.jm_username);
            this.local.set(Constants.LocalStorage.JmPwd, res.jm_password);
            this.jmLogin();
          }
        }, err => {
          console.log(err);
        })
    }
  }

  getCollege(id: any) {
    this.api.getCollegeById(id)
      .subscribe((res: any) => {
        if (res) {
          console.log("getCollegeById",res);
          this.config.collegeLogo = res.logo + '&imageView2/1/w/100/h/100';
          this.config.collegeName = res.name;
        }
      }, err => {
      });
  }

  getAdv() {
    this.api.postCdPublication({'position_codes': ['cpc_leftnav']})
      .subscribe((res: any) => {
        console.log(res,'res');
        let banner = [];
        if(res.length != 0 && res[0].listJson && res[0].listJson.length != 0) {
          for (let item of res[0].listJson) {
            banner.push({
              'source': JSON.parse(item).img_url,
              'link': JSON.parse(item).link,
              'title':''
            })
          }
          this.config.leftAdv = banner;
        }
      })
  }

  refreshToken() {
    let token = this.local.get(Constants.LocalStorage.RefreshToken);
    let params = {
      "grant_type": "refresh_token",
      "code": '',
      "refresh_token": token,
      "loginname": '',
      "password": '',
      "login_source": '',
    };

    // 获取token
    this.api.getToken(params)
      .subscribe(async (res: any) => {
        console.log("refresh_token =>" + res.access_token);
        this.config.token = res.access_token;
        let expiresDate = addSeconds(new Date(), res.expires_in);
        this.local.set(Constants.LocalStorage.AccessToken, res.access_token);
        this.local.set(Constants.LocalStorage.RefreshToken, res.refresh_token);
        this.local.set(Constants.LocalStorage.TokenExpiresDate, expiresDate);
        this.checkLogin();
      }, err => {
        this.local.clear();
        this.config.token = false;
        this.router.navigateByUrl('login');

        console.log('重新登陆  login');
        // this.router.navigateByUrl('login');
      })
  }

  getRegion(){
    this.api.getDict("region")
      .subscribe((res: any) => {
        this.local.set(Constants.LocalStorage.RegionData,JSON.stringify(res));
      }, err => {
      });
  }

  ngOnDestroy(){
    this.queryParams.unsubscribe();
  }

  checkUserLogin() {
    let param;
    let url = document.location.toString();
    let arrObj = url.split("?");
    let params = Object.create(null)
    if (arrObj.length > 1){
      arrObj = arrObj[1].split("&");
      arrObj.forEach( item =>{
        param = item.split("=");
        params[param[0]] = param[1]
      })
    }
    return params;
  }

}
