import { StorgeUtils } from './../../provider/utils/StorageUtils';
import { Validators } from './../../provider/utils/Validators';
import { Api } from './../../provider/Api';
import { BaseService } from './../../services/BaseService';
import { Injectable } from '@angular/core';
import { Observable, interval } from "rxjs";
import { map } from 'rxjs/operators';
import {Config} from "../../provider/Config";
import * as addSeconds from "date-fns/add_seconds";
import {Constants} from "../../provider/Constants";
import {XiaoMiMessage} from "../../provider/utils/XiaoMiMessage";


@Injectable()
export class bindingMobileService extends BaseService{

    countDownTotal : number = 60;//验证码再次发送倒计时
    countTime : number;

    smsCountTime : any;

    constructor(public api : Api,
                public local : StorgeUtils,
                public im: XiaoMiMessage,
                ){
        super();
    }

    checkMobile(user_mobile : any){
        if(user_mobile && user_mobile.length != 11){
            this.page.showMsg("请输入正确的手机号码");
        }else{
          this.page.toBind = true;

            // this.page.showLoading();
            // this.subscription.add(
            //     this.api.checkStudentMobile(user_mobile)
            //             .subscribe((res: any) => {
            //                 console.log(res);
            //                 this.page.setCheckResult(res);
            //                 this.page.hideLoading();
            //             }, err => {
            //                 let errorMsg = JSON.parse(err.error._body);
            //                   if(errorMsg.Errcode == "1023"){
            //                     this.page.showMsg("该手机号已被注册");
            //                 } else if(errorMsg.Errcode == "1014"){
            //                     this.page.showMsg("验证码无效，请重新获取");
            //                 } else {
            //                   this.page.showMsg(errorMsg.Errmsg);
            //                 }
            //
            //                 this.page.hideLoading();
            //             })
            // );
        }
    }

    getSMSCode(user_mobile : any){
        if(Validators.isEmpty(user_mobile)){
            console.log(user_mobile);
            this.page.showMsg("请输入手机号码!");
        }else if(user_mobile.length != 11){
            this.page.showMsg("请输入正确的手机号码");
        }else{
            //验证码倒计时
            this.countDownTotal = 60;

            if(this.smsCountTime){
                this.smsCountTime.unsubscribe();
            }

            this.smsCountTime = this.countDown(this.countDownTotal).subscribe((counterNumber : any) => {
                if(counterNumber > 0){
                    this.page.setSMSBtnText(counterNumber + "秒");
                    this.page.setSMSBtnClicable(true);
                }else{
                    this.page.setSMSBtnText("获取验证码");
                    this.page.setSMSBtnClicable(false);

                    if(this.smsCountTime){
                        this.smsCountTime.unsubscribe();
                    }
                }
            });
            /*
            this.subscription.add(this.countDown(this.countDownTotal).subscribe((counterNumber : any) => {
                //console.log(counterNumber);
                if(counterNumber > 0){
                    this.page.setSMSBtnText(counterNumber + "秒");
                    this.page.setSMSBtnClicable(true);
                }else{
                    this.page.setSMSBtnText("获取验证码");
                    this.page.setSMSBtnClicable(false);
                }
            }));*/

            let params = {
                "value" : user_mobile
            };

            this.subscription.add(
                this.api.getSMS(params)
                        .subscribe((res: any) => {
                            console.log(res);
                            this.page.showMsg("验证码已发送，请注意查收");
                        }, err => {
                            console.log(err);
                            //this.page.showMsg(err);
                        })
            );
        }
    }

    /**
     * 倒计时
     * @param time
     */
    countDown(time : number) : Observable<any>  {
        if (time < 0) time = 0;

        return interval(1000).pipe(
            map(()=>time--)
        );
    }

    bindingMobile(params : any){
          this.subscription.add(
            this.api.getCodeForStudentNo(params)
              .subscribe((res: any) => {
                console.log("getToken =>" + res.value);
                this.getToken(res.value);
              }, err => {
                console.log(err);
                this.page.hideLoading();
                if(err){
                  let errorMsg = JSON.parse(err.error._body);
                  if(errorMsg.Errcode == '1106') {
                    this.page.bindingMobile();
                  }else if(errorMsg.Errcode == '1013') {
                    this.page.showMsg(errorMsg.Errmsg.split(':')[1])
                  } else {
                    this.page.showMsg(errorMsg.Errmsg);
                  }
                }
              })
          )


    }

  spikBindingMobile(params : any){
    if(Validators.isEmpty(params.mobile)){
      this.page.showMsg("请输入手机号码!");
    }else if(Validators.isEmpty(params.vcode_text)){
      this.page.showMsg("请输入短信验证码!");
    }else{
      this.page.showLoading();
      if(this.smsCountTime){
        this.smsCountTime.unsubscribe();
      }
      this.subscription.add(
        this.api.updateUserMobile(params)
          .subscribe((res: any) => {
            console.log("getToken =>" + res.value);
            this.getAccount();
          }, err => {
            console.log(err);
            this.page.hideLoading();
            if(err){
              let errorMsg = JSON.parse(err.error._body);
              if(errorMsg.Errcode == '1106') {
                this.page.bindingMobile();
              }else if(errorMsg.Errcode == '1013') {
                this.page.showMsg(errorMsg.Errmsg.split(':')[1])
              }else if(errorMsg.Errcode == '1108') {
                this.page.showMsg('该手机号已注册，请前往工作啦PC/小程序绑定学号。')
              } else {
                this.page.showMsg(errorMsg.Errmsg);
              }
            }
          })
      )

    }
  }


  getToken(user_code: string) {
    let params = {
      "grant_type": "authorization_code",
      "code": user_code,
      "refresh_token": '',
      "loginname": '',
      "password": '',
      "login_source": 'web',
    };

    this.subscription.add(
      this.api.getToken(params)
        .subscribe(async (res: any) => {
          console.log("access_token =>" + res.access_token);

          Config.getInstance().token = res.access_token;

          let expiresDate = addSeconds(new Date(), res.expires_in);

          this.local.set(Constants.LocalStorage.AccessToken, res.access_token);
          this.local.set(Constants.LocalStorage.RefreshToken, res.refresh_token);
          this.local.set(Constants.LocalStorage.TokenExpiresDate, expiresDate);

          this.getAccount();
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          //this.page.showMsg(err.msg);
          this.page.hideLoading();
        })
    );
  }

  getAccount() {
    Config.getInstance().account_id = "";

    this.subscription.add(
      this.api.getAccount()
        .subscribe((res: any) => {
          console.log('getAccount',res);

          if (res.accountid) {
            Config.getInstance().account_id = res.accountid;
            Config.getInstance().jm_login_user = res.jm_username;
            Config.getInstance().jm_login_pwd = res.jm_password;

            this.local.set(Constants.LocalStorage.AccountId, res.accountid);
            this.local.set(Constants.LocalStorage.JmUser, res.jm_username);
            this.local.set(Constants.LocalStorage.JmPwd, res.jm_password);
            this.jmLogin();
            this.page.getResume();
          } else {
            this.page.goUserProfile();
          }
        }, err => {
          console.log('Errmsg');
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          this.page.hideLoading();
        })
    );
  }


  jmLogin() {
    if (Config.getInstance().jm_login_user && Config.getInstance().jm_login_pwd) {
      // this.api.getJmInitData()
      //   .subscribe((res: any) => {
      //     console.log('im login');
      //     this.im.login(Config.getInstance().jm_login_user);
      //   });
    } else {
      this.api.getAccount()
        .subscribe(async (res: any) => {
          console.log(res);
          if (res.accountid) {
            Config.getInstance().jm_login_user = res.jm_username;
            Config.getInstance().jm_login_pwd = res.jm_password;
            this.local.set(Constants.LocalStorage.JmUser, res.jm_username);
            this.local.set(Constants.LocalStorage.JmPwd, res.jm_password);
            this.jmLogin();
          }
        }, err => {
          console.log(err);
        })
    }
  }

  getRegion(){
    this.api.getDict("region")
      .subscribe((res: any) => {
        this.local.set(Constants.LocalStorage.RegionData,JSON.stringify(res));
      }, err => {
      });
  }

  postCheckSmsvcodeVerify(params){
    if(Validators.isEmpty(params.mobile)){
      this.page.showMsg("请输入手机号码!");
    }else if(Validators.isEmpty(params.vcode_text)){
      this.page.showMsg("请输入短信验证码!");
    }else {
      if (this.smsCountTime) {
        this.smsCountTime.unsubscribe();
      }
      this.api.checkSmsvcodeVerify(params)
        .subscribe((res: any) => {
          this.page.binding()
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          if(errorMsg.Errcode == "1014"){
            this.page.showMsg('验证码错误或已过期，请重试');
          } else {
            this.page.showMsg(errorMsg.Errmsg);
          }
         });
    }
  }

  postUserVerification(format_type, data) {
    let params = {
      "format_type": format_type,
      "data": data,
    };
    this.subscription.add(
      this.api.postUserVerification(params)
        .subscribe((res: any) => {
         this.page.phoneVerification(res);
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          //this.page.showMsg(err.msg);
        })
    );
  }

}
