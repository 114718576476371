import { ChatUtils } from './ChatUtils';
import {ChatMessage} from "../ChatMessage";
import { Constants } from './../Constants';
import { Config } from './../Config';
import { Injectable } from '@angular/core';
import { Events } from 'ionic-angular';
//import { LocalNotifications } from '@ionic-native/local-notifications';

declare var xiaomiIM;

@Injectable()
export class XiaoMiMessage{

    public config : Config;
    static instance: XiaoMiMessage;
    lastMessageTs : any;
    messageCount = 10;

    constructor(public events : Events,
                //private localNotifications: LocalNotifications,
                public chatUtils : ChatUtils,){
        XiaoMiMessage.instance = this;

        this.config = Config.getInstance();
    }

    /**
     * IM初始化 注册监听 小米IM登录
     * @param appAccount 登录账号
     */
    login(appAccount : any){
        let proxyServiceParams = {
          "url" : Constants.ApiUrl + "/api/mimc/token",
          "client_id" : Constants.ClientId,
          "access_token" : this.config.token,
          "accountid" : this.config.account_id,
        };

        xiaomiIM.init(appAccount,proxyServiceParams);

        xiaomiIM.addStatusListener(this.statusChangeListener);
        xiaomiIM.addReceiveMsgListener(this.receiveMsgListener);
        xiaomiIM.addServerAckListener(this.serverAckListener);
        xiaomiIM.addDisconnListener(this.disconnectListener);
        xiaomiIM.login();
        // return true;
    }

    /**
     * 获取对话列表
     */
    getDialogList(){
        return new Promise((resolve, reject) => {
            xiaomiIM.getDialogList().then(res=>{
                console.log("getDialogList");
                console.log(res);
                let list = new Array();
                if(res.data.length > 0){
                    var disMsg = new Base64();
                    let dateTime;
                    for(let i = 0;i < res.data.length;i++){
                        var date = new Date(parseInt(res.data[i].timestamp));
                        dateTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                        let model = {
                            "toUser" : res.data[i].name,
                            "last_message" : JSON.parse(disMsg.decode(res.data[i].lastMessage.payload)).content,
                            "dateTime" : dateTime,
                            "extra" : JSON.parse(res.data[i].extra)
                        };
    
                        list.push(model);
                    }
    
                    //XiaoMiMessage.instance.config.dialogList = list;
                    resolve(list);
                }

            },(error)=>{
                console.log(error);
                reject(error);
            })
        });
    }

    /**
     * 获取历史消息
     * 指的是拉取从指定的时间戳utcToTime(不包含utcToTime)向前count条的A与B之间的聊天记录。
     * @param toUser 对方用户名
     * @param uctime 时间戳
     * 
     */
    async getMessageHistory(toUser,uctime){
        let res = await xiaomiIM.getMessageList(toUser,XiaoMiMessage.instance.messageCount,uctime);
        console.log("getMessageList");
        console.log(res);

        var list = new Array();

        if(res.data && res.data.messages.length > 0){
            var disMsg = new Base64();
            
            let content,dateTime;
            for(let i = 0;i < res.data.messages.length;i++){
                //console.log(disMsg.decode(res.data.messages[i].payload));
              try {
                content = JSON.parse(disMsg.decode(res.data.messages[i].payload)).content;
              }catch (e) {
                console.log(e);
              }

                //console.log(res.data.messages[i].fromAccount +" : " + content);
                var date = new Date(parseInt(res.data.messages[i].ts));
                
                dateTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                /*
                let model = {
                    "type" : res.data.messages[i].bizType,      //消息类型
                    "content" : content,                        //消息内容
                    "from" : res.data.messages[i].fromAccount,  //发消息者
                    "createTime" : dateTime,                    //发送时间
                    "extra" : ""
                };*/

                let chatMessage = new ChatMessage();
                chatMessage.type = res.data.messages[i].bizType;
                chatMessage.content = content;
                chatMessage.dateTime = dateTime;
                chatMessage.toUserName = res.data.messages[i].fromAccount;
                if(res.data.messages[i].fromAccount == Config.getInstance().jm_login_user){
                    chatMessage.direct = "to";
                }else{
                    chatMessage.direct = "from";
                }

                list.push(chatMessage);
            }

            //通过时间戳获取 更多历史消息
            XiaoMiMessage.instance.lastMessageTs = res.data.messages[0].ts;
        }else{
            console.log("没有历史消息");
        }

        //console.log(list);
        return list;
    }

    /**
     * 获取历史消息
     * 指的是拉取从指定的时间戳utcToTime(不包含utcToTime)向前count条的A与B之间的聊天记录。
     * @param toUser 对方用户名
     * @param uctime 时间戳
    getMessageList(toUser,uctime,loadMore = false){
        xiaomiIM.getMessageList(toUser,XiaoMiMessage.instance.messageCount,uctime).then((res)=>{
            console.log("getMessageList");
            console.log(res);
            if(res.data.messages.length > 0){
                var disMsg = new Base64();
                var list = new Array();
                let content,dateTime;
                for(let i = 0;i < res.data.messages.length;i++){
                    //console.log(disMsg.decode(res.data.messages[i].payload));
                    content = JSON.parse(disMsg.decode(res.data.messages[i].payload)).content;
                    //console.log(res.data.messages[i].fromAccount +" : " + content);
                    var date = new Date(parseInt(res.data.messages[i].ts));
                    
                    dateTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                    
                    let chatMessage = new ChatMessage();
                    chatMessage.type = res.data.messages[i].bizType;
                    chatMessage.content = content;
                    chatMessage.dateTime = dateTime;
                    chatMessage.toUserName = res.data.messages[i].fromAccount;
                    if(res.data.messages[i].fromAccount == Config.getInstance().jm_login_user){
                        chatMessage.direct = "to";
                    }else{
                        chatMessage.direct = "from";
                    }

                    list.push(chatMessage);
                }
                //console.log(list);

                //通过时间戳获取 更多历史消息
                XiaoMiMessage.instance.lastMessageTs = res.data.messages[0].ts;

                if(loadMore){
                    XiaoMiMessage.instance.events.publish("im:messageLoadMore",list);
                }else{
                    XiaoMiMessage.instance.events.publish("im:messageList",list);
                }
                
            }else{
                console.log("没有历史消息");
            }
            
        },(error)=>{
            console.log(error);
        })
    }*/

    /**
     * 加载更多 历史消息
     * @param toUser 
     */
    async loadMore(toUser){
      console.log(XiaoMiMessage.instance.lastMessageTs);
        if(XiaoMiMessage.instance.lastMessageTs){
            console.log("加载更多");
            return await this.getMessageHistory(toUser,XiaoMiMessage.instance.lastMessageTs);
        }
    }

    //登录状态监听
    statusChangeListener(bindResult, errType, errReason, errDesc) {
        if (bindResult) {
            console.log("login succeed");
            XiaoMiMessage.instance.events.publish("im:login","succeed");
        } else {
            console.log("login failed.errReason=" + errReason + ",errDesc=" + errDesc + ",errType=" + errType);
            XiaoMiMessage.instance.events.publish("im:login","fail");
        }
    }

    /**
     * 消息接收监听
     * @param message 
     */
    receiveMsgListener(message) {
        var date = new Date(parseInt(message.getTimeStamp()));
        //console.log("time: " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + " " + date.getMilliseconds());

        console.log(message);
        //var disMsg = new Base64();
        console.log("biztype=" + message.getBizType());
        //console.log(message.getFromAccount() + " to " + mimc_appAccount + ":" + disMsg.decode(JSON.parse(message.getPayload()).content));
        console.log("消息来自：" + message.getFromAccount());
        console.log(message.getPayload());
        console.log("消息内容：" + JSON.parse(message.getPayload()).content);

        let msgDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();


        let chatMessage = new ChatMessage();
        chatMessage.type = message.getBizType();
        chatMessage.content = JSON.parse(message.getPayload()).content;
        chatMessage.dateTime = msgDate;
        chatMessage.toUserName = message.getFromAccount();
        if(message.getFromAccount() == Config.getInstance().jm_login_user){
            chatMessage.direct = "to";
        }else{
            chatMessage.direct = "from";
        }
            //更新对话列表
            console.log("更新对话列表");
            XiaoMiMessage.instance.chatUtils.messageReceiveUpdate(chatMessage,'');
    }

    //发送消息后，服务器接收到消息ack的回调
    serverAckListener(packetId, sequence, timeStamp, errMsg) {
        console.log("receive msg ack:" + packetId + ",sequence=" + sequence + ",ts=" + timeStamp);
        return;
    }

    disconnectListener() {
        console.log("XiaoMiMessage disconnect");
        XiaoMiMessage.instance.events.publish("im:disconnect");
    }

    /**
     * 发送消息
     * @param toUser 对方名称
     * @param message 消息体
     */
    send(toUser,msgType,message){
        return new Promise((resolve, reject) => {
            xiaomiIM.sendMsg(toUser,msgType,message).then(res => {//发送成功
              console.log('发送成功!');
                resolve(res);
            },error=>{//发送失败
                reject(error);
            });
        });
    }

    updateExtra(toUser,extra){
        xiaomiIM.updateDialogExtra(toUser,extra).then(res => {
            console.log(res);
        },error=>{//发送失败
            console.log(error);
        });
    }

    //退出
    logout(){
        xiaomiIM.logout();
    }

    isInBlackList(toUser){
        xiaomiIM.isInBlackList(toUser).then(res => {
            console.log(res);
        },error=>{
            console.log(error);
        });
    }
}

var Base64 = function() {
    var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    this.encode = function(input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = _utf8_encode(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
                _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
                _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
        }
        return output;
    };

    this.decode = function(input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            enc1 = _keyStr.indexOf(input.charAt(i++));
            enc2 = _keyStr.indexOf(input.charAt(i++));
            enc3 = _keyStr.indexOf(input.charAt(i++));
            enc4 = _keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }
        output = _utf8_decode(output);
        return output;
    };

    var _utf8_encode = function(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }
        return utftext;
    };

    var _utf8_decode = function(utftext) {
        var string = "";
        var i = 0;
        var c = 0;
        var c2 = 0,c3 = 0;
        while (i < utftext.length) {
            c = utftext.charCodeAt(i);
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    }
}
