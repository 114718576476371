import { Config } from './../../provider/Config';
import { Validators } from './../../provider/utils/Validators';
import { JsonData } from './../../provider/JsonData';
import { Api } from './../../provider/Api';
import { BaseService } from './../../services/BaseService';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginCollegeService extends BaseService {

    constructor(public api: Api) {
        super();
    }

    getEducation() {
        this.page.setEducationData(JSON.parse(JsonData.education));
    }

    collegeSearch(typeName: string, keyword: string, dict_level: number) {
        let params = {
            "name": keyword,
            "level": dict_level
        };

        this.subscription.add(
            this.api.matchSearch(typeName, params)
                .subscribe((res: any) => {
                    if (typeName == 'school') {
                        this.page.setCollegeResult(res);
                    } else {
                        this.page.setMajorResult(res);
                    }

                }, err => {
                    console.log('error=> ' + err);
                })
        );
    }

    searchPost(params) {
        this.subscription.add(
            this.api.matchSearch("post_type", params)
                .subscribe((res: any) => {
                    this.page.setSearchPost(res);
                }, err => {
                    console.log('error=> ' + err);
                })
        );
    }

    add(params: any) {
        if (Validators.isEmpty(params.graduation_school)) {
            this.page.showMsg("请选择学校");
        } else if (Validators.isEmpty(params.major_name)) {
            this.page.showMsg("请选择专业");
        } else if (Validators.isEmpty(params.education)) {
            this.page.showMsg("请选择学历");
        } else if (Validators.isEmpty(params.start_date)) {
            this.page.showMsg("请选择入学日期");
        } else if (Validators.isEmpty(params.end_date)) {
            this.page.showMsg("请选择毕业日期");
        } else {
            console.log(11111)
            this.page.showLoading();
            this.subscription.add(
                this.api.addEducation(params)
                    .subscribe(async (res: any) => {
                        console.log(res);
                        params.subid = res.value;

                        if (!Config.getInstance().education) {
                            Config.getInstance().education = new Array();
                        }
                        Config.getInstance().education.push(params);

                        Config.getInstance().user.school = params.graduation_school;
                        Config.getInstance().user.schoolCode = params.school_code;
                        Config.getInstance().user.major = params.major_name;
                        Config.getInstance().collegeId = res.collegeid;

                        this.page.submitDone();
                        this.page.hideLoading();

                    }, err => {
                        let errorMsg = JSON.parse(err.error._body);
                        this.page.showMsg(errorMsg.Errmsg);
                        //this.page.showMsg(err.msg);
                        this.page.hideLoading();
                    })
            );
        }
    }

    getEducationList(type: string) {
        this.subscription.add(
            this.api.getDict(type)
                .subscribe((res: any) => {
                    // console.log(res)
                    this.page.setTreeAll(res)
                }, err => {
                    console.log('error=> ' + err);
                })
        );
    }
}