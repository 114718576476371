/**
 * 消息
 */
export class ChatMessage {
    public static TEXT = "text";
    public static POSITION = "position";//职位
    public static TALENT = "talent";
    public static RESUME = "resume";//我的简历
    public static IMAGE = "image";//图片
    public static INVITATION = "invitation";//邀请
    public static REPLY = "reply";//邀请回复
    public static WECHAT = "wechat";//微信号
    public static SHIELD = "shield";//屏蔽
    public static OFFER = "offer";//录用
    public static OFFER_REPLY = "offer_reply";//录用回复
    public static SIGNING = "signing";// 签约
    public static SIGNING_REPLY = "signing_reply";//签约回复

    public static INAPPROPRIATE = "inappropriate";//被企业标记为不合适
    
    public static MILKROUND = "milkround";//招聘会
    public static CAMPUSTALK = "campustalk";//宣讲会

    public static MULTITEXT = "multitext";//通知 富文本

    public static ASKFORRESUME = "ask_for_resume";//企业索要简历

    /**
     * 消息类型
     *  
        system,     /// 系统消息
        question,   /// 常见问题
        position,   /// 职位
        talent,     /// 人才信息      
        text,       /// 文本
        resume,     /// 简历
        invitation, /// 邀请面试
        reply       /// 回复面试
                    0:拒绝
                    1:同意
                    2:取消
        image       /// 图片
     */
    public type : string;
    //消息内容
    public content : any;
    /**
     * 聊天界面显示 左右方向
     * from 接收到的消息
     * to   发送的消息
     */
    public direct : string;
    //本机用户
    public fromUserName : string;

    //对方用户
    public toUserName : string;

    //日期时间
    public dateTime : any;
    public dateTimeToShow : any;
    public isRead : boolean;

    public avatar : any;
}

export class JmMessage{
    public type;
    public content;
}

/**
 * 人才信息
 */
export class TalentMessage{
    public id;
    public resume_number;
    public talent_name;
    public grade;
    public education;
    public education_text;
    public major;
    public major_text;
    public school;
}
