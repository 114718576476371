import { Router } from '@angular/router';
import { Constants } from './../../provider/Constants';
import { Api } from './../../provider/Api';
import { StorgeUtils } from './../../provider/utils/StorageUtils';
import { BasePage } from './../base/BasePage';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { bindingMobileService } from './binding-mobile.service';
import {Config} from "../../provider/Config";
import {Validators} from "../../provider/utils/Validators";

@Component({
  selector: 'app-binding-mobile',
  templateUrl: './binding-mobile.component.html',
  styleUrls: ['./binding-mobile.component.css']
})
export class 
BindingMobileComponent extends BasePage implements OnInit {

  toBind = false;

  mobile: string;//手机号/账号
  sms_code : any;

  buttonDisabled = false;
  btnText : string = "获取验证码";
  showUpdateMobileModel:any = false;
  params = {};

  constructor(public messageService: MessageService,
              public service : bindingMobileService,
              public api : Api,
              public router : Router,
              public local : StorgeUtils) {

    super(messageService);
  }

  ngOnInit() {
    this.service.init(this);
  }

  checkMobile(){
   // this.service.checkMobile(this.mobile);
    // this.service.postUserVerification('mobile',this.mobile);

   if( Validators.isMobilePhone(this.mobile)){
     this.toBind = true;
   }else{
     this.showMsg('手机号格式有误,请重新输入');
   }
  }

  setCheckResult(res){
    if(res.value == false){
      this.toBind = true;
    }else{
      if(res.year_same) {
        this.showMsg("此手机号码已绑定学号，请使用其他手机号码！");
      } else {
        this.showUpdateMobileModel = true;
      }
    }
  }

  setSMSBtnText(text : string){
    this.btnText = text;
  }

  setSMSBtnClicable(clickable : boolean){
    this.buttonDisabled = clickable;
  }

  getSMSCode(){
    if(!this.buttonDisabled){
      this.service.getSMSCode(this.mobile);
    }
  }
  
  binding(){
    let bindParams: any = {
      'mobile': this.mobile
    };

    if(this.local.get("loginSanCode")){
      // 用户扫码登录
      let loginSanCodeParams = JSON.parse(this.local.get("loginSanCode"));
      bindParams.wechat_bind = {};
      bindParams.unionid= loginSanCodeParams.unionid;
      bindParams.nickname= loginSanCodeParams.nickname;
      bindParams.headimgurl=loginSanCodeParams.headimgurl;
      bindParams.login_type= 'sms';
      bindParams.smscode= this.sms_code;
      bindParams.wechat_bind.unionid= loginSanCodeParams.unionid;
      bindParams.wechat_bind.nickname= loginSanCodeParams.nickname;
      bindParams.wechat_bind.headimgurl= loginSanCodeParams.headimgurl;
      bindParams.wechat_bind.account_type= 'talent';
      this.service.bindingMobile(bindParams);
    }else if(this.local.get("loginNoParams")){
      // 学号登录
      this.local.get("loginNoParams") ? Object.assign(bindParams,JSON.parse(this.local.get("loginNoParams"))) : '';
      this.service.bindingMobile(bindParams);
    } else {
      // 就业网跳转处理
      this.service.spikBindingMobile(this.params);
    }
  }
  /*
  binding(){
    let bindParams: any = {
      'mobile': this.mobile
    };

    if(this.local.get("loginSanCode")){
      // 用户扫码登录
      let loginSanCodeParams = JSON.parse(this.local.get("loginSanCode"));
      if (loginSanCodeParams.errorcode) {
        this.showMsg('当前账户未绑定，请用手机号登录');
        return false;
      }
      bindParams.wechat_bind = {};
      bindParams.auth_code=loginSanCodeParams.auth_code;
      bindParams.wechat_bind.auth_code= loginSanCodeParams.auth_code;      
      this.service.getToken(bindParams.auth_code);
    }else if(this.local.get("loginNoParams")){
      // 学号登录
      this.local.get("loginNoParams") ? Object.assign(bindParams,JSON.parse(this.local.get("loginNoParams"))) : '';
      this.service.bindingMobile(bindParams);
    } else {
      // 就业网跳转处理
      this.service.spikBindingMobile(this.params);
    }
  }
  */
  getResume(){
    this.api.getResume()
            .subscribe((res: any) => {
                console.log(res);
                if(res && res.resumeid){

                  Config.getInstance().resume_id = res.resumeid;
                  Config.getInstance().resume_number = res.resume_number;

                  this.local.set(Constants.LocalStorage.ResumeId, res.resumeid);
                  this.local.set(Constants.LocalStorage.ResumeNumber, res.resume_number);
                  //判断是否第一次注册学校
                  if(res.education_backgrounds && res.education_backgrounds.length > 0){


                    Config.getInstance().user.name = res.talent_base.name;

                    if (res.talent_base.logo) {
                      Config.getInstance().user.avatar = res.talent_base.logo;
                    } else {
                      if (res.talent_base.gender == "01") {
                        Config.getInstance().user.avatar = Config.getInstance().maleDefaultAvatar;
                      } else if (res.talent_base.gender == "02") {
                        Config.getInstance().user.avatar = Config.getInstance().femaleDefaultAvatar;
                      } else {
                        Config.getInstance().user.avatar = Config.getInstance().userDefaultAvatar;
                      }
                    }

                    Config.getInstance().user.wechat = res.talent_base.wechat;
                    Config.getInstance().user.email = res.talent_base.email;
                    Config.getInstance().userMobile = res.talent_base.mobile;
                    Config.getInstance().studentId = res.talent_base.no;
                    Config.getInstance().collegeId = res.talent_base.collegeid;

                    this.getCollege(Config.getInstance().collegeId);//获取学校Logo

                    this.local.set(Constants.LocalStorage.CollegeId, res.talent_base.collegeid);

                    if (res.education_backgrounds) {
                      Config.getInstance().user.school = res.education_backgrounds[0].graduation_school;
                      Config.getInstance().user.schoolCode = res.education_backgrounds[0].school_code;
                      Config.getInstance().user.major = res.education_backgrounds[0].major_status_text;
                    }

                    Config.getInstance().education = res.education_backgrounds;//教育经历

                    Config.getInstance().resumeLevel = res.level;//简历完整度

                    if (res.job_target.work_city[0]) {
                      Config.getInstance().user.targetCity = res.job_target.work_city[0].text;
                      Config.getInstance().user.targetCityCode = res.job_target.work_city[0].code;
                    }

                    this.local.set(Constants.LocalStorage.User, Config.getInstance().user);

                    if (res.skills && res.skills.length > 0) {
                      Config.getInstance().skills = res.skills;//技能
                      Config.getInstance().proSkills = res.pro_skills;//职业技能
                      Config.getInstance().jobTarget = res.job_target;//职位筛选条件
                      Config.getInstance().workExperiences = res.work_experiences;//工作经历
                      Config.getInstance().training = res.training_experiences;//培训经历
                      Config.getInstance().certificates = res.certificates;//证书
                      Config.getInstance().opus = res.works;//作品
                      Config.getInstance().project = res.project_experiences;//项目/比赛经历
                      Config.getInstance().School_Position = res.school_positions ; // 在校职务
                      Config.getInstance().Languages_Get = res.languages; // 语言能力
                      Config.getInstance().job_status = res.talent_base.job_status; // 求职意向
                      Config.getInstance().job_status_text = res.talent_base.job_status_text; // 求职意向
                      Config.getInstance().self_evaluates = res.self_evaluates; // 自我描述

                      Config.getInstance().unReadTotal = 0;
                      if (res.pro_skills && res.pro_skills.length > 0) {
                        this.hideLoading();
                        this.config.isLogin = true;
                        this.router.navigateByUrl('workspace');
                      }else{
                        this.router.navigateByUrl('userposition');
                      }
                    } else {
                      this.router.navigateByUrl('userskill');
                    }
                  }else {
                    this.router.navigateByUrl('usercollege');
                  }
                }

            }, err => {
              console.log(err);
              this.router.navigateByUrl('login');
            })
      }


  getCollege(id: any) {
    this.api.getCollegeById(id)
      .subscribe((res: any) => {
        console.log(res);
        if (res) {
          Config.getInstance().collegeLogo = res.logo + '&imageView2/1/w/100/h/100';
          Config.getInstance().collegeName = res.name;
        }
      }, err => {

      });
  }

  goUserProfile() {
    this.service.getRegion();
    this.router.navigateByUrl('userprofile');
  }

  isUpdateMobile(e) {
    this.showUpdateMobileModel = false;
    if(e) {
      this.toBind = true;
      this.params['is_migrate'] = false;
    }
  }

  checkSmsvcodeVerify(){
    if(Validators.isMobilePhone(this.mobile)){
      this.params['mobile'] = this.mobile;
      this.params['vcode_text'] = this.sms_code;
      this.service.postCheckSmsvcodeVerify(this.params);
    }else {
      this.showMsg('手机号格式有误,请重新输入');
    }
  }

  phoneVerification(res){
    if(res){
      this.toBind = true;
    } else {
      this.showMsg('手机号格式有误,请重新输入');
    }
  }


}
