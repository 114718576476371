import {Injectable} from '@angular/core';

declare var window;

@Injectable()
export class EncryptUtils {

    //JS加密
    private convertDate = function(date) {
        let res = date.getFullYear() + ',' + (date.getMonth() + 1) + ',' + date.getDate() + ',' + date.getHours() + ',' + date.getMinutes() + ',' + date.getSeconds();
        return res;
    };

    //JS加密
    public encrypt (clientId) {
        let CryptoJS = window.CryptoJS;
        let data = clientId + '--' + this.convertDate(new Date());
        let key = CryptoJS.enc.Utf8.parse('607490BE-18CA-43D7-B11A-57E2621B');
        let iv = CryptoJS.enc.Utf8.parse('2D59831C-78AC-4227-B3F3-CE656636');
        let encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
         return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    };

}
