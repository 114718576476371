import {Constants} from './../../provider/Constants';
import {StorgeUtils} from './../../provider/utils/StorageUtils';
import {Config} from './../../provider/Config';
import {Validators} from './../../provider/utils/Validators';
import {Api} from './../../provider/Api';
import {BaseService} from './../../services/BaseService';
import {Injectable} from '@angular/core';
import * as addSeconds from 'date-fns/add_seconds';
import {Observable, interval} from "rxjs";
import {map} from 'rxjs/operators';
import {XiaoMiMessage} from "../../provider/utils/XiaoMiMessage";
import {Router} from "@angular/router";

@Injectable()
export class loginService extends BaseService {

  countDownTotal: number = 60;//验证码再次发送倒计时
  countTime: number;

  smsCountTime: any;

  constructor(public api: Api,
              public local: StorgeUtils,
              // public jm : JMessageUtils,
              public im: XiaoMiMessage,
              public router: Router,
  ) {
    super();
  }

  getVcodeImage() {
    this.subscription.add(
      this.api.getVcodeImage()
        .subscribe((res: any) => {
          if (res) {
            this.page.setVcodeImage(res);
          }
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          //console.log('error=> ' + err.msg);
        })
    );
  }

  match(typeName: string, keyword: string, dict_level: number) {
    let params = {
      "name": keyword,
      "level": dict_level
    };

    this.subscription.add(
      this.api.matchSearch(typeName, params)
        .subscribe((res: any) => {
          this.page.setItems(res);
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          //console.log('error=> ' + err);
        })
    );
  }

  getSMSCode(params: any) {
    if (Validators.isEmpty(params.mobile)) {
      this.page.showMsg("请输入手机号码!");
    } else if (Validators.isEmpty(params.vcode_text)) {
      this.page.showMsg("请输入验证码!");
    } else {
      let _params = {
        "mobile": params.mobile,
        "sms_type": "login",
        "vcode_verify": {
          "vcodeid": params.vcode_id,
          "vcode_text": params.vcode_text
        }
      };

      this.subscription.add(
        this.api.getSMSVcode(_params)
          .subscribe((res: any) => {
            console.log(res);
            this.page.showMsg("验证码已发送，请注意查收");

            //验证码倒计时
            this.countDownTotal = 60;

            if (this.smsCountTime) {
              this.smsCountTime.unsubscribe();
            }

            this.smsCountTime = this.countDown(this.countDownTotal).subscribe((counterNumber: any) => {
              if (counterNumber > 0) {
                this.page.setSMSBtnText(counterNumber + "秒");
                this.page.setSMSBtnClicable(true);
              } else {
                this.page.setSMSBtnText("获取验证码");
                this.page.setSMSBtnClicable(false);

                if (this.smsCountTime) {
                  this.smsCountTime.unsubscribe();
                }
              }
            });
          }, err => {
            console.log(err);
            let errorMsg = JSON.parse(err.error._body);

            if (errorMsg.Errcode == "1014") {
              this.page.showMsg(errorMsg.Errmsg);
              this.page.setSMSBtnText("获取验证码");
              this.page.setSMSBtnClicable(false);
              this.page.getVcodeImage();
            }

            if (errorMsg.Errcode == "1013") {
              this.page.showMsg("手机号码无效");
            }

            if(errorMsg.Errcode == "1037"){
              this.page.showMsg(errorMsg.Errmsg);
            }
          })
      );
    }
  }
  getSMSVcodeWithPsw(phone){
    if (Validators.isEmpty(phone)) {
      this.page.showMsg("请输入手机号码!");return;
    }
    if (!Validators.isMobilePhone(phone)) {
      this.page.showMsg("手机号格式错误");return;
    }

      this.subscription.add(
        this.api.getSMSVcodeWithPsw(phone)
          .subscribe((res: any) => {
            console.log(res);
            this.page.showMsg("验证码已发送，请注意查收");

            //验证码倒计时
            this.countDownTotal = 60;

            if (this.smsCountTime) {
              this.smsCountTime.unsubscribe();
            }

            this.smsCountTime = this.countDown(this.countDownTotal).subscribe((counterNumber: any) => {
              if (counterNumber > 0) {
                this.page.setSMSBtnText(counterNumber + "秒");
                this.page.setSMSBtnClicable(true);
              } else {
                this.page.setSMSBtnText("获取验证码");
                this.page.setSMSBtnClicable(false);

                if (this.smsCountTime) {
                  this.smsCountTime.unsubscribe();
                }
              }
            });
          }, err => {
            console.log(err);
            let errorMsg = JSON.parse(err.error._body);

            if (errorMsg.Errcode == "1014") {
              this.page.showMsg(errorMsg.Errmsg);
              this.page.setSMSBtnText("获取验证码");
              this.page.setSMSBtnClicable(false);
              this.page.getVcodeImage();
            }

            if (errorMsg.Errcode == "1013") {
              this.page.showMsg("手机号码无效");
            }

            if(errorMsg.Errcode == "1037"){
              this.page.showMsg(errorMsg.Errmsg);
            }
          })
      );
  }


  /**
   * 倒计时
   * @param time
   */
  countDown(time: number): Observable<any> {
    if (time < 0) time = 0;

    return interval(1000).pipe(
      map(() => time--)
    );
  }

  /**
   * 学号登录
   */
  loginForStudent(params: any) {
    if (Validators.isEmpty(params.code)) {
      this.page.showMsg("请选择学校!");
    } else if (Validators.isEmpty(params.no)) {
      this.page.showMsg("请输入学号!");
    } else if (Validators.isEmpty(params.password)) {
      this.page.showMsg("请输入密码!");
    } else {
      this.page.showLoading();
      this.subscription.add(
        this.api.getCodeForStudentNo(params)
          .subscribe((res: any) => {

            console.log("getToken =>" + res.value);
            this.getToken(res.value);
          }, err => {
            console.log(err);
            this.page.hideLoading();
            if(err){
              let errorMsg = JSON.parse(err.error._body);

              if(errorMsg.Errcode == '1106') {
                this.page.bindingMobile();
              }else if(errorMsg.Errcode == '1013') {
                this.page.showMsg(errorMsg.Errmsg.split(':')[1])
              } else {
                this.page.showMsg(errorMsg.Errmsg);
              }



            }

          })
      );
    }
  }

  /**
   * 手机登录
   * @param user_mobile
   * @param wopenkey
   * @param sms_code
   */
  async loginForPhone(user_mobile: any, wopenkey: any,sms_code: any) {
    if (Validators.isEmpty(user_mobile)) {
      this.page.showMsg("请输入手机号码!");
    } else if (Validators.isEmpty(sms_code)) {
      this.page.showMsg("请输入短信验证码!");
    } else {
      this.page.showLoading();

      // if (this.smsCountTime) {
      //   this.smsCountTime.unsubscribe();
      // }
      let login_action = this.local.get('login_action')

      //清除所有本地数据
      this.local.clear();
      if(login_action){
        this.local.set('login_action',login_action)
      }

      Config.getInstance().token = null;
      Config.getInstance().account_id = null;
      Config.getInstance().resume_id = null;
      Config.getInstance().resume_number = null;

      let params = {
        "mobile": user_mobile,
        "wopenkey": wopenkey,
        "smscode": sms_code,
        "login_type": 'sms'
      };

      this.subscription.add(
        this.api.post_sanCode_login(params)
          .subscribe((res: any) => {
            console.log("code =>" + res.value);
            this.getToken(res.value);
          }, err => {
            //console.log('error=> ' + err.msg);
            let errorMsg = JSON.parse(err.error._body);

            if (errorMsg.Errcode == "1014") {
              this.page.showMsg("验证码无效，请重新获取");
            }

            if (errorMsg.Errcode == "1013") {
              this.page.showMsg("手机号码无效");
            }

            // this.page.setSMSBtnText("获取验证码");
            // this.page.setSMSBtnClicable(false);

            this.page.hideLoading();
          })
      );
    }
  }


  getToken(user_code: string) {
    let params = {
      "grant_type": "authorization_code",
      "code": user_code,
      "refresh_token": '',
      "loginname": '',
      "password": '',
      "login_source": 'web',
    };

    this.subscription.add(
      this.api.getToken(params)
        .subscribe(async (res: any) => {
          console.log("access_token =>" + res.access_token);

          Config.getInstance().token = res.access_token;

          let expiresDate = addSeconds(new Date(), res.expires_in);

          this.local.set(Constants.LocalStorage.AccessToken, res.access_token);
          this.local.set(Constants.LocalStorage.RefreshToken, res.refresh_token);
          this.local.set(Constants.LocalStorage.TokenExpiresDate, expiresDate);

          this.getAccount();
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          //this.page.showMsg(err.msg);
          this.page.hideLoading();
        })
    );
  }

  getAccount() {
    Config.getInstance().account_id = "";

    this.subscription.add(
      this.api.getAccount()
        .subscribe((res: any) => {
          console.log(res);

          if (res.accountid) {
              Config.getInstance().account_id = res.accountid;
              Config.getInstance().jm_login_user = res.jm_username;
              Config.getInstance().jm_login_pwd = res.jm_password;

              this.local.set(Constants.LocalStorage.AccountId, res.accountid);
              this.local.set(Constants.LocalStorage.JmUser, res.jm_username);
              this.local.set(Constants.LocalStorage.JmPwd, res.jm_password);

              this.jmLogin();
              if (res && !res.logo) {
                this.local.set('noLogo', true);
                this.page.goUserProfile()
                return
              }
              this.getUserInfo();
            } else {
              this.page.goUserProfile();
            }
        }, err => {
          console.log('Errmsg');
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          this.page.hideLoading();
        })
    );
  }

  getResume() {
    this.subscription.add(
      this.api.getResume()
        .subscribe((res: any) => {
          console.log(res);
          this.page.hideLoading();
          if (res.resumeid) {
            Config.getInstance().resume_id = res.resumeid;
            Config.getInstance().resume_number = res.resume_number;

            this.local.set(Constants.LocalStorage.ResumeId, res.resumeid);
            this.local.set(Constants.LocalStorage.ResumeNumber, res.resume_number);
            //判断是否第一次注册学校
            if(res.education_backgrounds && res.education_backgrounds.length > 0){
              Config.getInstance().user.name = res.talent_base.name;
            if (res.talent_base.logo) {
              Config.getInstance().user.avatar = res.talent_base.logo;
            } else {
              if (res.talent_base.gender == "01") {
                Config.getInstance().user.avatar = Config.getInstance().maleDefaultAvatar;
              } else if (res.talent_base.gender == "02") {
                Config.getInstance().user.avatar = Config.getInstance().femaleDefaultAvatar;
              } else {
                Config.getInstance().user.avatar = Config.getInstance().userDefaultAvatar;
              }
            }

            Config.getInstance().user.wechat = res.talent_base.wechat;
            Config.getInstance().user.email = res.talent_base.email;
            Config.getInstance().userMobile = res.talent_base.mobile;
            Config.getInstance().studentId = res.talent_base.no;
            Config.getInstance().collegeId = res.talent_base.collegeid;

            this.getCollege(Config.getInstance().collegeId);//获取学校Logo

            this.local.set(Constants.LocalStorage.CollegeId, res.talent_base.collegeid);

            if (res.education_backgrounds) {
              Config.getInstance().user.school = res.education_backgrounds[0].graduation_school;
              Config.getInstance().user.schoolCode = res.education_backgrounds[0].school_code;
              Config.getInstance().user.major = res.education_backgrounds[0].major_status_text;
            }

            Config.getInstance().education = res.education_backgrounds;//教育经历

            Config.getInstance().resumeLevel = res.level;//简历完整度

            if (res.job_target.work_city[0]) {
              Config.getInstance().user.targetCity = res.job_target.work_city[0].text;
              Config.getInstance().user.targetCityCode = res.job_target.work_city[0].code;
            }

            this.local.set(Constants.LocalStorage.User, Config.getInstance().user);

            if (res.skills && res.skills.length > 0) {
              Config.getInstance().skills = res.skills;//技能
              Config.getInstance().proSkills = res.pro_skills;//职业技能
              Config.getInstance().jobTarget = res.job_target;//职位筛选条件
              Config.getInstance().workExperiences = res.work_experiences;//工作经历
              Config.getInstance().training = res.training_experiences;//培训经历
              Config.getInstance().certificates = res.certificates;//证书
              Config.getInstance().opus = res.works;//作品
              Config.getInstance().project = res.project_experiences;//项目/比赛经历
              Config.getInstance().School_Position = res.school_positions ; // 在校职务
              Config.getInstance().Languages_Get = res.languages; // 语言能力
              Config.getInstance().job_status = res.talent_base.job_status; // 求职意向
              Config.getInstance().job_status_text = res.talent_base.job_status_text; // 求职意向
              Config.getInstance().self_evaluates = res.self_evaluates; // 自我描述

              Config.getInstance().unReadTotal = 0;
              if (res.pro_skills && res.pro_skills.length > 0) {
                this.page.goWorkspace();
              }else{
                this.router.navigateByUrl('userposition');
              }
            } else {
              this.page.loginNext();
            }
            }else {
              this.page.goUserCollege();
            }
          }
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          //this.page.showMsg(err.msg);
          this.page.hideLoading();
        })
    );
  }

  getUserInfo() {
    this.subscription.add(
      this.api.getUserInfo()
        .subscribe((res: any) => {
          console.log('getUserInfo',res);
            this.getResume()
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
          this.page.hideLoading();
        })
    );
  }

  getCollege(id: any) {
    this.api.getCollegeById(id)
      .subscribe((res: any) => {
        console.log(res);
        if (res) {
          Config.getInstance().collegeLogo = res.logo + '&imageView2/1/w/100/h/100';
          Config.getInstance().collegeName = res.name;
        }
      }, err => {
        let errorMsg = JSON.parse(err.error._body);
        this.page.showMsg(errorMsg.Errmsg);
      });
  }

  jmLogin() {
    if (Config.getInstance().jm_login_user && Config.getInstance().jm_login_pwd) {
      // this.api.getJmInitData()
      //   .subscribe((res: any) => {
      //     console.log('im login');
      //     this.im.login(Config.getInstance().jm_login_user);
      //   });
    } else {
      this.api.getAccount()
        .subscribe(async (res: any) => {
          console.log(res);
          if (res.accountid) {
            Config.getInstance().jm_login_user = res.jm_username;
            Config.getInstance().jm_login_pwd = res.jm_password;
            this.local.set(Constants.LocalStorage.JmUser, res.jm_username);
            this.local.set(Constants.LocalStorage.JmPwd, res.jm_password);
            this.jmLogin();
          }
        }, err => {
          console.log(err);
        })
    }
  }


  getCollegeByCode(code : any){
    this.subscription.add(
      this.api.getCollegeByCode(code)
        .subscribe((res: any) => {
          if(res){
            this.page.setCollegeInfo(res);
          }
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
        })
    );
  }

  getRegion(){
    this.api.getDict("region")
      .subscribe((res: any) => {
        this.local.set(Constants.LocalStorage.RegionData,JSON.stringify(res));
        this.router.navigateByUrl('userprofile');
      }, err => {
      });
  }

  // 用户已扫码 调用登录
  postSanCodeLogin(params){
    this.api.post_sanCode_login(params)
      .subscribe((res: any) => {
        this.getToken(res.value);
      }, err => {
        let errorMsg = JSON.parse(err.error._body);
        if(errorMsg.Errcode == "1102") {
          this.page.bindingMobile();
        }else {
          this.page.showMsg(errorMsg.Errmsg);
        }
      });
  }

}
