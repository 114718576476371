import { Router } from '@angular/router';
import { LoginUserSkillService } from './login-user-skill.service';
import { BasePage } from './../base/BasePage';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login-user-skill',
  templateUrl: './login-user-skill.component.html',
  styleUrls: ['./login-user-skill.component.css']
})
export class LoginUserSkillComponent extends BasePage implements OnInit {

  proSkill = new Array();
  genSkill = new Array();
  skill = new Array();

  constructor(public messageService: MessageService,
              public service : LoginUserSkillService,
              public router : Router) {
    super(messageService);
  }

  ngOnInit() {
    this.config.skills = new Array();
    
    this.service.init(this);
    this.service.getSkills();
  }

  setSkill(res : any){
    for(let item of res){
      item.selected = false;
      this.genSkill.push(item);
      /*
      if(item.parentcode == "28"){//提取通用技能
        this.genSkill.push(item);
      }else{
        this.proSkill.push(item);
      }*/
    }

    if(this.config.skills && this.config.skills.length > 0){
      for(let item of res){
        for(let skill of this.config.skills){
          if(item.code == skill.code){
            item.selected = true;
          }
        }
      }
    }
  }

  select(item : any){
    item.selected = !item.selected;

    let exist = false;
    let num;
    for(let i = 0;i < this.config.skills.length;i++){
      if(item.code == this.config.skills[i].code){
        exist = true;
        num = i;
        break;
      }
    }

    if(!exist){
      if(this.config.skills.length < 5){
        this.config.skills.push(item);
      }else{
        item.selected = false;
        this.showMsg("最多只能选择5个技能");
      }
      
    }else{
      item.selected = false;
      this.config.skills.splice(num,1);
    }
    console.log(this.config.skills);
  }

  done(){
    this.router.navigateByUrl('userposition');
  }

  next(){
    if(this.config.skills.length > 0){
      for(let item of this.config.skills){
        this.skill.push(item.code);
      }
      console.log(JSON.stringify(this.skill));
      this.service.update(JSON.stringify(this.skill));
    }else{
      this.showMsg("请选择你的技能");
    }
  }
}
