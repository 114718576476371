export class JsonData {
  /**
   *　学历
   */
  public static education = JSON.stringify([
    {
      "code": "01",
      "parentcode": "0",
      "text": "中专及以下",
      "level": 1
    }, 
    {
      "code": "02", 
      "parentcode": "0", 
      "text": "大专", 
      "level": 1}, 
    {
      "code": "03",
      "parentcode": "0",
      "text": "本科",
      "level": 1
    },
    {
      "code": "04", 
      "parentcode": "0",
      "text": "硕士", 
      "level": 1
    }, 
    {
      "code": "05",
      "parentcode": "0",
      "text": "MBA",
      "level": 1
    }, 
    {
      "code": "06",
      "parentcode": "0", 
      "text": "EMBA", 
      "level": 1
    }, 
    {
      "code": "07",
      "parentcode": "0",
      "text": "博士",
      "level": 1
    }
  ]);

  public static salary = JSON.stringify([{"code": "01", "parentcode": "0", "text": "1K以下", "level": 1}, {
    "code": "02",
    "parentcode": "0",
    "text": "1K-2k",
    "level": 1
  }, {"code": "03", "parentcode": "0", "text": "2k-4k", "level": 1}, {
    "code": "04",
    "parentcode": "0",
    "text": "4k-6k",
    "level": 1
  }, {"code": "05", "parentcode": "0", "text": "6k-8k", "level": 1}, {
    "code": "06",
    "parentcode": "0",
    "text": "8k-10k",
    "level": 1
  }, {"code": "07", "parentcode": "0", "text": "10k-15k", "level": 1}, {
    "code": "08",
    "parentcode": "0",
    "text": "15k-25k",
    "level": 1
  }, {"code": "09", "parentcode": "0", "text": "25k以上", "level": 1},]);

  public static salaryForFilter = JSON.stringify([{
    "code": "null",
    "parentcode": "0",
    "text": "不限",
    "level": 1
  }, {"code": "01", "parentcode": "0", "text": "1K以下", "level": 1}, {
    "code": "02",
    "parentcode": "0",
    "text": "1K-2k",
    "level": 1
  }, {"code": "03", "parentcode": "0", "text": "2k-4k", "level": 1}, {
    "code": "04",
    "parentcode": "0",
    "text": "4k-6k",
    "level": 1
  }, {"code": "05", "parentcode": "0", "text": "6k-8k", "level": 1}, {
    "code": "06",
    "parentcode": "0",
    "text": "8k-10k",
    "level": 1
  }, {"code": "07", "parentcode": "0", "text": "10k-15k", "level": 1}, {
    "code": "08",
    "parentcode": "0",
    "text": "15k-25k",
    "level": 1
  }, {"code": "09", "parentcode": "0", "text": "25k以上", "level": 1}]);

  public static employerType = JSON.stringify([
    {"code": "01", "text": "地方国企"},
    {"code": "02", "text": "民营"},
    {"code": "03", "text": "外资"},
    {"code": "04", "text": "合资"},
    {"code": "05", "text": "代表处"},
    {"code": "06", "text": "央企"},
    {"code": "08", "text": "国家机关"},
    {"code": "09", "text": "事业单位"},
    {"code": "10", "text": "个人工商户"}
    ]);

  public static entryTime = JSON.stringify([{"code": "01", "text": "随时入职"}, {
    "code": "02",
    "text": "三天内入职"
  }, {"code": "03", "text": "七天内入职"}, {"code": "04", "text": "30天内入职"}]);

  public static companyScale = JSON.stringify([{
    "code": "01",
    "parentcode": "0",
    "text": "少于50人",
    "level": 1,
    "order": 1
  }, {"code": "02", "parentcode": "0", "text": "50-150人", "level": 1, "order": 2}, {
    "code": "03",
    "parentcode": "0",
    "text": "150-500人",
    "level": 1,
    "order": 3
  }, {"code": "04", "parentcode": "0", "text": "500-1000人", "level": 1, "order": 4}, {
    "code": "05",
    "parentcode": "0",
    "text": "1000-5000人",
    "level": 1,
    "order": 5
  }, {"code": "06", "parentcode": "0", "text": "5000-10000人", "level": 1, "order": 6}, {
    "code": "07",
    "parentcode": "0",
    "text": "10000人以上",
    "level": 1,
    "order": 7
  }]);

  // 求职状态
  public static job_status = JSON.stringify([
    {
      "code": "01",
      "text": "正在找工作",
      "active": false
    }, {
      "code": "02",
      "text": "在校生,可参加实习",
      "active": false
    }, {
      "code": "03",
      "text": "已找到工作",
      "active": false
    }
  ]);

  public static LanguagesRank = JSON.stringify([{
    "code": "01",
    "parentcode": "0",
    "text": "精通",
    "level": 1,
    "order": 1
  }, {"code": "02", "parentcode": "0", "text": "熟练", "level": 1, "order": 2}, {
    "code": "03",
    "parentcode": "0",
    "text": "良好",
    "level": 1,
    "order": 3
  }, {"code": "04", "parentcode": "0", "text": "一般", "level": 1, "order": 4},
  ]);


  public static mainMenu = JSON.stringify([
    {
      "name": "首页",
      "icon": "fa-home",
      "link": "home"
    },
    {
      "name": "找工作",
      "icon": "fa-line-chart",
      "open": false,
      "children": [{
        "name": "热招职位",
        "icon": "fa-line-chart",
        "link": "home"
      }, {
        "name": "推荐单位",
        "link": "charts"
      }, {
        "name": "双选会",
        "link": "charts"
      }, {
        "name": "宣讲会",
        "link": "charts"
      }]
    },
    {
      "name": "消息中心",
      "icon": "fa-comments-o",
      "open": false,
      "children": [{
        "name": "tree",
        "link": "tree"
      }]
    },
    {
      "name": "个人中心",
      "icon": "fa-user-circle-o",
      "open": false,
      "children": [{
        "name": "我的简历",
        "link": "resume"
      }, {
        "name": "学籍信息",
        "link": "resume"
      }]
    }
  ]);


  public static feedBackList = [{
    "name": "求职攻略",
    "ItemStatus": false,
    "msgList": [{
      "showStatus": false,
      "msg": "填写简历的注意事项",
      "answer": "请按照【简历】页提示认真完善简历，更完善的简历更易赢得HR青睐。"
    },
      {
        "showStatus": false,
        "msg": "其他使用指南",
        "answer": "可微信关注「工作啦」校园招聘公众号（goworkla2）了解更多使用指南。"
      }
    ]
  },
    {
      "name": "热点问题",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "生源信息填写错误怎么修改",
        "answer": "如已提交审核（审核中、审核通过），请在工作时间联系学校就业处驳回再修改提交。"
      },
        {
          "showStatus": false,
          "msg": "绑定学籍/学号密码是什么",
          "answer": "初始密码是您身份证号后六位，如后六位有字母按大写输入"
        }
      ]
    },
    {
      "name": "账号问题",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "绑定学籍/学号初始密码是什么",
        "answer": "初始密码是您身份证后六位，如后六位有字母按大写输入"
      },
        {
          "showStatus": false,
          "msg": "密码忘记了怎么办",
          "answer": "操作路径：1、在PC端登录页面点击“忘记密码”根据提示操作即可。2、小程序端如果绑定学号后修改密码，路径是：个人中心-隐私设置-修改密码。"
        },
        {
          "showStatus": false,
          "msg": "怎么更改联系电话",
          "answer": "您可以按以下路径操作：小程序-个人中心-隐私设置-修改手机号。"
        },
      ]
    },
    {
      "name": "生源学籍",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "登录学号显示学籍信息不存在/未找到绑定的学籍信息/生源信息不存在",
        "answer": "先确定学生学号是否正确无误，如无误请联系学校就业处。"
      },
        {
          "showStatus": false,
          "msg": "资料中困难类别是什么意思？",
          "answer": "请与工作时间联系学校就业处"
        },
        {
          "showStatus": false,
          "msg": "生源地区填写的是哪儿的地址？",
          "answer": "1、生源地为高考所在地（多次高考，以最后一次为准）2、专升本生源也填的是高考所在地 3、更多问题可工作时间联系学校就业处。"
        },
        {
          "showStatus": false,
          "msg": "定向生是什么意思？",
          "answer": "定向生是为了帮助边远地区、少数民族地区和工作环境比较艰苦的行业培养人才，保证他们得到一定数量的毕业生而制定的一项政策。考生自愿填报有关高等学校定向就业招生志愿并按有关政策一旦被录取为定向生，须在入学注册前与高校及定向就业单位签订有关定向就业协议。极个别专业学生为定向生，其他都是非定向，请根据自己实际情况选择。}"
        },
        {
          "showStatus": false,
          "msg": "生源信息审核需要多久？",
          "answer": "生源信息由学校老师审核，具体审核时间因校而异，详情请工作时间联系学校就业处"
        },
        {
          "showStatus": false,
          "msg": "怎么看自己信息是否审核通过",
          "answer": "可以通过点击：就业事务-生源信息  来查看"
        },
        {
          "showStatus": false,
          "msg": "生源信息填写错误怎么修改",
          "answer": "如已提交审核（审核中、审核通过），请在工作时间联系学校就业处驳回再修改提交。"
        },
      ]
    },
    {
      "name": "简历相关",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "期望工作城市如何选择/设置",
        "answer": "设置路径：简历-求职意向-“+”号-选择期望（工作）城市"
      },
        {
          "showStatus": false,
          "msg": "是否可以上传简历",
          "answer": "暂时无此功能"
        },
        {
          "showStatus": false,
          "msg": "求职意向怎么修改",
          "answer": "修改路径：我的-求职意向-职位意向"
        },
        {
          "showStatus": false,
          "msg": "投出的简历能撤回吗？",
          "answer": "投出的简历不能撤回，但是您修改后会同步到企业"
        }
      ]
    },
    {
      "name": "求职操作",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "怎么使用平台找工作",
        "answer": "可进入小程序【机会】、【就业活动】、【通知】页查找公司机会"
      },
        {
          "showStatus": false,
          "msg": "怎么投简历",
          "answer": "投递路径：您心仪的职位-职位详情-投递简历"
        },
        {
          "showStatus": false,
          "msg": "怎么可以看到参加本校双选会的企业信息和职位信息",
          "answer": "可进入小程序【就业活动】页查找"
        },
        {
          "showStatus": false,
          "msg": "能帮我推荐某个行业的工作吗",
          "answer": "可进入小程序【机会】页，使用按“行业”筛选"
        }
      ]
    },
    {
      "name": "举报企业",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "招聘者如果收取费用怎么办?",
        "answer": "如果有企业在您入职前收取任何费用，比如服装费、住宿费、体检费、保证金等等，请谨慎，并且把相关证据提交给我们，一旦核实我们会对其进行封号处理"
      }]
    },
    {
      "name": "签约中心",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "签约中心在哪里？",
        "answer": "1、小程序端，就业事务--签约中心；2、PC端，个人中心---签约中心"
      },
        {
          "showStatus": false,
          "msg": "就业类别选择就业协议就业或劳动合同就业签约后，就业模式怎么选择？",
          "answer": "1、线上签约：适用于学生、用人单位、高校三方全过程在线签约；2、协议书打印签约：适用于“央企”、“国企”等不方便授权个人在线签约的用人单位签约；3、已签协议书备案：适用于已下发纸质协议书高校，学生与企业线下签约、线上备案。"
        },
        {
          "showStatus": false,
          "msg": "线上签约时，新签约界面信息（未填写、填写错），企业收到签约链接已完成签约，没法修改怎么办？",
          "answer": "1、如果企业完成签约，本人还未确认签约，学生可点击拒绝签约，重新建立新签约，完善信息后将链接发给企业；2、如果本人已提交审核而学校暂未审核，需联系学校老师审核不通过，重新签约；3、如果学校已通过审核，需与用人单位和高校沟通后发起解约，解约完成后重新签约。"
        },
        {
          "showStatus": false,
          "msg": "选择协议书打印签约时，怎么找不到生成的协议书？",
          "answer": "小程序IOS系统无法下载，建议到PC端操作；如果PC端登录下载后找不到生成的协议书，排查浏览器是否设置拦截，或者更换浏览器下载。"
        },
        {
          "showStatus": false,
          "msg": "协议书打印签约时，协议书编号填写错了怎么办？",
          "answer": "选择线上签约和协议书打印签约时，协议书编号一栏会自动隐藏，不需要学生修改或者填写，不会出现协议书编号填写错误的情况。"
        },
        {
          "showStatus": false,
          "msg": "就业协议书打印有限定吗？",
          "answer": "具体打印需按学校要求，详询学校老师"
        },
        {
          "showStatus": false,
          "msg": "想解约怎么操作呢？",
          "answer": "1、如果高校开通该项功能，您可以点击在线解约，下载解约函经企业盖章后上传，提交后由老师审核。2、如果高校未开通该项功能，您需要按照本校公布的解约流程进行解约。"
        }
      ]
    },
    {
      "name": "档案管理",
      "ItemStatus": false,
      "msgList": [{
        "showStatus": false,
        "msg": "怎么查询档案管理",
        "answer": "在档案管理界面，输入学号、身份证号、报到证号进行查询"
      },
        {
          "showStatus": false,
          "msg": "为什么查询不到档案信息",
          "answer": "1、学校还未开始使用（建议学校通知后再使用）2、建议使用学号或报到证号查询"
        }
      ]
    },
  ]


  public static levelData = [
    {
      "value": "01",
      "dict_type": "employment_type_04",
      "label": "科研部门"
    },
    {
      "value": "02",
      "dict_type": "employment_type_04",
      "label": "科研机构"
    },
    {
      "value": "03",
      "dict_type": "employment_type_04",
      "label": "科研院所"
    },
    {
      "value": "04",
      "dict_type": "employment_type_04",
      "label": "高校"
    },
    {
      "value": "05",
      "dict_type": "employment_type_04",
      "label": "企业"
    },

    {
      "value": "01",
      "dict_type": "employment_type_06",
      "label": "选聘高校毕业生到村任职"
    },
    {
      "value": "02",
      "dict_type": "employment_type_06",
      "label": "乡村义务教育阶段学校教师特设岗位计划"
    },
    {
      "value": "03",
      "dict_type": "employment_type_06",
      "label": "高校毕业生\"三支一扶\"计划"
    },
    {
      "value": "04",
      "dict_type": "employment_type_06",
      "label": "农技员"
    },
    {
      "value": "05",
      "dict_type": "employment_type_06",
      "label": "大学生志愿服务西部计划"
    },
    {
      "value": "01",
      "dict_type": "employment_type_07",
      "label": "选聘高校毕业生到村任职"
    },
    {
      "value": "02",
      "dict_type": "employment_type_07",
      "label": "乡村义务教育阶段学校教师特设岗位计划"
    },
    {
      "value": "03",
      "dict_type": "employment_type_07",
      "label": "高校毕业生\"三支一扶\"计划"
    },
    {
      "value": "04",
      "dict_type": "employment_type_07",
      "label": "农技员"
    },
    {
      "value": "01",
      "dict_type": "employment_type_14",
      "label": '工作'
    },
    {
      "value": "02",
      "dict_type": "employment_type_14",
      "label": '学习'
    }
  ]

  
      
     
    
 
}
